<script lang="ts" setup>
const infoStore = useInfoStore();

onMounted(() => {
  infoStore.init();
});

onBeforeUnmount(() => {
  infoStore.close();
});
</script>

<template>
  <slot />
</template>
