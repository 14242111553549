import type { useConfirm as originalUseConfirm } from '#imports';

declare module 'vue' {
  interface ComponentCustomProperties {
    $confirm: ReturnType<typeof originalUseConfirm>;
  }
}

export const useCustomConfirm: typeof originalUseConfirm = () => {
  const nuxtApp = useNuxtApp();

  return nuxtApp.vueApp.config.globalProperties.$confirm;
};
