<script lang="ts" setup>
const mainStore = useMainStore();
const route = useRoute();
const { t, tm, rt } = useI18n();

const isHelpDialogVisible = ref(false);
</script>

<template>
  <Dialog
    v-model:visible="mainStore.isSubscriptionDialogVisible"
    :header="t('title')"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
    class="w-120"
  >
    <Stepper linear class="text-sm md:text-lg -ml-2">
      <StepItem value="1" class="!flex-row">
        <div class="flex flex-col">
          <Step />
          <StepPanel class="!bg-transparent !flex" />
        </div>

        <p class="m-0 pt-1 pb-4">
          {{ t('description') }}

          <Button
            @click="isHelpDialogVisible = true"
            :label="t('show_list')"
            severity="help"
            size="small"
            outlined
            class="mt-3 flex"
          />
        </p>
      </StepItem>

      <StepItem value="2" class="!flex-row">
        <div class="flex flex-col">
          <Step />
          <StepPanel class="!bg-transparent !flex" />
        </div>

        <p v-if="mainStore.loaded" class="m-0 pt-1 pb-4">
          {{ t('auth_granted') }}

          <Message severity="info" class="mt-3 w-fit">
            {{ t('auth_info', { name: mainStore.user.name }) }}
          </Message>
        </p>
        <p v-else class="m-0 pt-1">
          {{ t('auth_required') }}

          <Button
            as="nuxt-link"
            :to="{
              path: '/auth/sign-in',
              query: { authRedirect: route.fullPath, subscribe: '1' },
            }"
            @click="mainStore.isSubscriptionDialogVisible = false"
            :label="t('auth_action')"
            outlined
            class="w-full mt-3"
          />
        </p>
      </StepItem>

      <StepItem v-if="mainStore.loaded" value="3" class="!flex-row">
        <div class="flex flex-col">
          <Step />
          <StepPanel class="!bg-transparent !flex" />
        </div>

        <p class="m-0 pt-1">
          {{ t('payment_description') }}

          <Button
            as="a"
            href="/telegram-payment"
            target="_blank"
            :label="t('payment_action')"
            outlined
            class="w-full mt-3"
          />
        </p>
      </StepItem>
    </Stepper>
  </Dialog>

  <Dialog
    v-model:visible="isHelpDialogVisible"
    :header="t('help_title')"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
    class="w-120"
  >
    <div class="text-sm md:text-lg leading-snug">
      <p class="mt-0 mb-3">{{ t('help_description.0') }}</p>
      <p class="mt-0 mb-3">{{ t('help_description.1') }}</p>

      <ul class="list-disc pl-4 m-0">
        <li v-for="(item, index) in tm('help_list')" :key="index">
          {{ rt(item) }}
        </li>
      </ul>
    </div>

    <template #footer>
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="isHelpDialogVisible = false"
        text
      />
    </template>
  </Dialog>
</template>

<i18n>
ru:
  title: Получение подписки
  description: Подписка на Sex Play даст вам и вашему партнеру безграничный доступ ко всем развлечениям и функциям!
  show_list: Список возможностей
  auth_required: Для начала давайте познакомимся — войдите в свой аккаунт или зарегистрируйтесь.
  auth_action: Войти в аккаунт
  auth_granted: Вход в аккаунт выполнен, все хорошо :)
  auth_info: Здравствуйте {name}
  payment_description: Оплатите подписку через Telegram — бот подскажет все необходимые действия, а процесс максимально
    упрощен для вашего удобства!
  payment_action: Получить удовольствие
  close: Закрыть
  help_title: Список возможностей
  help_description:
    - Подписка откроет для вас множество возможностей и функций платформы Sex Play. Подписка также сразу будет активна и
      для вашего партнера.
    - 'Подписка дает вам:'
  help_list:
    - Все доступные категории секс заданий;
    - Все паки вопросов в игре в интересы;
    - Игра с двух устройств с партнером;
    - Виджет статуса желания заняться сексом;
    - Отправка идеи для секса партнеру;
    - Больше сохранений настроек секс заданий.

en:
  title: Obtaining a subscription
  description: Subscription Sex Play Give you and your partner unlimited access to all entertainment and features!
  show_list: List of possibilities
  auth_required: First, let’s get acquainted – sign in to your account or register.
  auth_action: Log in to the account
  auth_granted: 'Log in to the account, all is well :'
  auth_info: Hello {name}
  payment_description: Pay your subscription through Telegram The bot will prompt all the necessary actions, and the
    process is as simplified as possible for your convenience!
  payment_action: Have fun.
  close: Close.
  help_title: List of possibilities
  help_description:
    - Subscription will open up a lot of platform features and features for you Sex Play. The subscription will also be
      active for your partner.
    - 'The subscription gives you:'
  help_list:
    - All available sex assignment categories;
    - All questions in the game of interests;
    - Playing with two devices with a partner;
    - Widget status of desire to have sex;
    - Sending an idea for sex to a partner;
    - More saving settings for sex assignments.

es:
  title: Obtención de una suscripción
  description: Suscripción Sex Play ¡Dale a usted y a su pareja acceso ilimitado a todos los entretenimientos y características!
  show_list: Lista de posibilidades
  auth_required: En primer lugar, vamos a conocer – registrarse en su cuenta o registrarse.
  auth_action: Inicia sesión en la cuenta
  auth_granted: 'Inicia sesión en la cuenta, todo está bien :'
  auth_info: Hola {name}
  payment_description: Paga tu suscripción a través de Telegram El bot impulsará todas las acciones necesarias, y el
    proceso es tan simplificado como sea posible para su comodidad!
  payment_action: Diviértete.
  close: Cerca.
  help_title: Lista de posibilidades
  help_description:
    - Suscripción abrirá un montón de características de plataforma y características para usted Sex Play. La
      suscripción también estará activa para su socio.
    - 'La suscripción le da:'
  help_list:
    - Todas las categorías de asignación sexual disponibles;
    - Todas las preguntas en el juego de intereses;
    - Jugar con dos dispositivos con un socio;
    - Widget estado del deseo de tener sexo;
    - Enviar una idea de sexo a un socio;
    - Más ajustes de ahorro para asignaciones sexuales.

de:
  title: Erhalt eines Abonnements
  description: Abonnement Sex Play Geben Sie und Ihrem Partner unbegrenzten Zugriff auf alle Unterhaltung und Features!
  show_list: Liste der Möglichkeiten
  auth_required: Zuerst lassen Sie sich kennenlernen, um Ihr Konto oder Ihre Registrierung anzumelden.
  auth_action: Melden Sie sich im Konto an
  auth_granted: 'Melden Sie sich auf dem Konto, alles ist gut:'
  auth_info: Hallo {name}
  payment_description: Bezahlen Sie Ihr Abonnement durch Telegram Der Bot wird alle notwendigen Aktionen veranlassen, und
    der Prozess ist so vereinfacht wie möglich für Ihre Bequemlichkeit!
  payment_action: Viel Spaß.
  close: Fast.
  help_title: Liste der Möglichkeiten
  help_description:
    - Abonnement wird eine Menge von Plattform-Funktionen und Funktionen für Sie öffnen Sex Play. Das Abonnement ist
      auch für Ihren Partner aktiv.
    - 'Das Abonnement gibt Ihnen:'
  help_list:
    - Alle verfügbaren Kategorien von Sexzuweisungen;
    - Alle Fragen im Interessesbereich;
    - Spielen mit zwei Geräten mit einem Partner;
    - Widget-Status des Wunsches, Sex zu haben;
    - Senden einer Idee für Sex an einen Partner;
    - Mehr Speichereinstellungen für Sexaufträge.

fr:
  title: Obtenir un abonnement
  description: Abonnement Sex Play Donnez-vous et votre partenaire un accès illimité à tous les divertissements et fonctionnalités!
  show_list: Liste des possibilités
  auth_required: Tout d'abord, faites connaissance – connectez-vous à votre compte ou inscrivez-vous.
  auth_action: Connectez-vous au compte
  auth_granted: 'Connectez-vous au compte, tout est bien :'
  auth_info: Bonjour {name}
  payment_description: Payez votre abonnement par Telegram Le bot va déclencher toutes les actions nécessaires, et le
    processus est aussi simplifié que possible pour votre commodité!
  payment_action: Amuse-toi bien.
  close: Presque.
  help_title: Liste des possibilités
  help_description:
    - L'abonnement ouvrira beaucoup de fonctionnalités et de fonctionnalités pour vous Sex Play. L'abonnement sera
      également actif pour votre partenaire.
    - "L'abonnement vous donne:"
  help_list:
    - Toutes les catégories d ' affectation sexuelle disponibles;
    - Toutes les questions dans le jeu des intérêts;
    - Jouer avec deux appareils avec un partenaire;
    - l'état widget du désir d'avoir des relations sexuelles;
    - Envoyer une idée de sexe à un partenaire;
    - Plus de paramètres de sauvegarde pour les missions sexuelles.
</i18n>
