export const ProfileAPI = {
  signIn: openapiStrictFetch({
    url: 'v2/auth/sign-in',
    method: HTTPMethod.post,
  }),
  signUp: openapiStrictFetch({
    url: 'v2/auth/sign-up',
    method: HTTPMethod.post,
  }),
  twoFactor: openapiStrictFetch({
    url: 'v2/2fa/authenticate',
    method: HTTPMethod.post,
  }),
  twoFactorGenerate: openapiStrictFetch({
    url: 'v2/2fa/generate',
    method: HTTPMethod.post,
  }),
  twoFactorEnable: openapiStrictFetch({
    url: 'v2/2fa/enable',
    method: HTTPMethod.post,
  }),
  twoFactorDisable: openapiStrictFetch({
    url: 'v2/2fa/disable',
    method: HTTPMethod.post,
  }),
  recovery2fa: openapiStrictFetch({
    url: 'v2/recovery/2fa',
    method: HTTPMethod.post,
  }),

  passkeyList: openapiStrictFetch({
    url: 'v2/auth/passkey/list',
  }),
  passkeyRegister: openapiStrictFetch({
    url: 'v2/auth/passkey/register/options',
    method: HTTPMethod.post,
  }),
  passkeyRegisterVerify: openapiStrictFetch({
    url: 'v2/auth/passkey/register/verify',
    method: HTTPMethod.post,
  }),
  passkeyAuth: openapiStrictFetch({
    url: 'v2/auth/passkey/auth/options',
    method: HTTPMethod.post,
  }),
  passkeyAuthVerify: openapiStrictFetch({
    url: 'v2/auth/passkey/auth/verify',
    method: HTTPMethod.post,
  }),
  passkeyDisable: openapiStrictFetch({
    url: 'v2/auth/passkey/disable/{passkeyId}',
    method: HTTPMethod.delete,
  }),

  completeRecovery2fa: openapiStrictFetch({
    url: 'v2/recovery/2fa',
    method: HTTPMethod.put,
  }),
  recoverPassword: openapiStrictFetch({
    url: 'v2/auth/password-recovery',
    method: HTTPMethod.post,
  }),
  currentEmailRecovery: openapiStrictFetch({
    url: 'v2/auth/password-recovery',
    method: HTTPMethod.get,
  }),
  changePasswordByCode: openapiStrictFetch({
    url: 'v2/auth/password-recovery',
    method: HTTPMethod.put,
  }),
  update: openapiStrictFetch({
    url: 'v2/user',
    method: HTTPMethod.put,
  }),
  changePassword: openapiStrictFetch({
    url: 'v2/user/change-password',
    method: HTTPMethod.put,
  }),
  changeWantSex: openapiStrictFetch({
    url: 'v2/user/want-sex',
    method: HTTPMethod.put,
  }),
  createVerify: openapiStrictFetch({
    url: 'v2/profile/verify',
    method: HTTPMethod.post,
  }),
  acceptVerify: openapiStrictFetch({
    url: 'v2/profile/verify',
    method: HTTPMethod.put,
  }),
  updateNotification: openapiStrictFetch({
    url: 'v2/profile/notification',
    method: HTTPMethod.post,
  }),
  acceptInvite: openapiStrictFetch({
    url: 'v2/user/invite',
    method: HTTPMethod.post,
  }),
  cancelInvite: openapiStrictFetch({
    url: 'v2/user/invite',
    method: HTTPMethod.delete,
  }),

  getOnboardings: openapiStrictFetch({
    url: 'v2/onboardings',
  }),
  saveOnboarding: openapiStrictFetch({
    url: 'v2/onboardings',
    method: HTTPMethod.post,
  }),
};
