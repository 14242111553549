import type { ResponseError, UserType } from '#types';

export const useUserStore = defineStore('user', () => {
  const router = useRouter();
  const route = useRoute();

  const user = useState('user_details', () => ({}) as UserType);
  const isLogout = useState(() => false);
  const isOnboardingProcessed = useState(() => false);

  const loaded = computed(() => !!user.value.id);

  const authorized = computed({
    get: () => useCookie('authorized').value,
    set: (value) => {
      useCookie('authorized').value = value;
    },
  });

  const subscriptionExpiredAt = computed<Date | null>(() => {
    if (isOnboardingProcessed.value) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    }

    if (!user.value.id) return null;

    const value = new Date(user.value.subscriptionExpiredAt);
    const pairedValue =
      user.value.pairedUser &&
      new Date(user.value.pairedUser?.subscriptionExpiredAt);

    const max = pairedValue && pairedValue > value ? pairedValue : value;

    if (max < new Date()) return null;

    return max;
  });

  const pro = computed(() => !!subscriptionExpiredAt.value);

  const setUser = (value: UserType) => {
    user.value = value;
    isLogout.value = false;

    return value;
  };

  const logout = (clear = false) =>
    UserAPI.logout().then(() => {
      if (!clear && route.meta.authGuard) router.push('/auth/sign-in');

      return Promise.resolve().then(() => {
        authorized.value = null;
        user.value = {} as UserType;
        isLogout.value = true;
      });
    });

  const loadUser = async (fallback?: boolean) => {
    if (loaded.value && fallback) return user.value;

    return UserAPI.details()
      .then(setUser)
      .catch((e: ResponseError) => {
        if (import.meta.client && e.context?.response?.status === 401)
          return logout(!fallback);
      });
  };

  return {
    user,
    authorized,
    loaded,
    isLogout,
    subscriptionExpiredAt,
    pro,
    isOnboardingProcessed,

    setUser,
    logout,
    loadUser,
  };
});
