<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();

const visible = defineModel<boolean>('visible');

const isMoved = ref(false);
const touchStartXPosition = ref<number>(0);
const touchDeltaXPosition = ref<number>(0);
const menuContainer = ref<HTMLDivElement>();

const normalizeXPosition = (event: TouchEvent | MouseEvent) => {
  return 'touches' in event ? event.touches[0].clientX : event.clientX;
};

const onTouchMove = (event: TouchEvent | MouseEvent) => {
  const xPosition = normalizeXPosition(event);

  touchDeltaXPosition.value = Math.min(
    0,
    xPosition - touchStartXPosition.value,
  );
  isMoved.value = true;
};

const onTouchEnd = (event: Event) => {
  if (isMoved.value) event.preventDefault();
  if (touchDeltaXPosition.value < -150) {
    setTimeout(() => {
      visible.value = false;
    });
  }
  touchStartXPosition.value = 0;
  touchDeltaXPosition.value = 0;
  setTimeout(() => {
    isMoved.value = false;
  });

  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchmove', onTouchMove);
    menuContainer.value.removeEventListener('mousemove', onTouchMove);
    menuContainer.value.removeEventListener('touchend', onTouchEnd);
    menuContainer.value.removeEventListener('mouseup', onTouchEnd);
  }
};

const onTouchStart = (event: TouchEvent | MouseEvent) => {
  if (!visible.value) return;

  touchStartXPosition.value = normalizeXPosition(event);

  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchmove', onTouchMove);
    menuContainer.value.addEventListener('mousemove', onTouchMove);
    menuContainer.value.addEventListener('touchend', onTouchEnd);
    menuContainer.value.addEventListener('mouseup', onTouchEnd);
  }
};

const onOverflowClick = () => {
  if (!isMoved.value) visible.value = false;
};

watch(
  () => route.fullPath,
  () => {
    visible.value = false;
  },
);

watch(visible, (value) => {
  if (value) document.body.classList.add('overflow-hidden');
  else document.body.classList.remove('overflow-hidden');
});

onMounted(() => {
  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchstart', onTouchStart, {
      passive: true,
    });
    menuContainer.value.addEventListener('mousedown', onTouchStart, {
      passive: true,
    });
  }
});

onBeforeUnmount(() => {
  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchstart', onTouchStart);
    menuContainer.value.removeEventListener('mousedown', onTouchStart);
  }
});
// TODO: скролл по двум осям не очень хорошо на мобилке
</script>

<template>
  <div ref="menuContainer">
    <Transition name="fade">
      <div
        v-if="visible"
        class="fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-10 transition"
        @click="onOverflowClick"
      />
    </Transition>
    <Transition name="sidebar">
      <div
        v-if="visible"
        class="p-2 top-0 left-0 bottom-0 fixed z-20 pointer-events-none max-w-full"
        :style="
          touchStartXPosition
            ? `transform: translateX(${touchDeltaXPosition}px)`
            : 'transition: transform 0.25s ease'
        "
      >
        <aside
          class="bg-surface-900/60 backdrop-blur-md border border-surface-800 border-solid rounded-xl h-full flex flex-col select-none w-80 max-w-full pointer-events-auto"
        >
          <Button
            @click="visible = false"
            icon="pi pi-times"
            severity="secondary"
            text
            rounded
            class="!absolute top-4 right-4"
            :aria-label="t('close')"
          />
          <nav
            class="overflow-y-auto p-2 py-8 my-auto gap-2 no-scroll"
          >
            <slot />
          </nav>
        </aside>
      </div>
    </Transition>
  </div>
</template>

<i18n>
en:
  close: Close menu

ru:
  close: Закрыть меню

de:
  close: Menü schließen

es:
  close: Cerrar menú

fr:
  close: Fermer le menu
</i18n>
