<script lang="ts" setup>
import type { ResponseError } from '#types';

const toast = useCustomToast();

defineProps<{ visible: boolean }>();

const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void;
}>();

const { t } = useI18n();

const password = ref('');
const newPassword = ref('');

const { execute, isLoading, isValid } = useRequest(
  ProfileAPI.changePassword,
  () => ({
    body: { prevPassword: password.value, nextPassword: newPassword.value },
  }),
  { validation: () => newPassword.value.length >= 8 },
);

const resetDialog = () => {
  password.value = '';
  newPassword.value = '';
  emit('update:visible', false);
};

const saveNewPassword = () => {
  execute()
    ?.then(() => {
      resetDialog();
      toast.add({
        severity: 'success',
        summary: t('success_password_updating'),
        life: 3000,
      });
    })
    .catch((e: ResponseError) => {
      if (e.context?.response?.status === 403) {
        toast.add({
          severity: 'error',
          summary: t('error_wrong_old_password'),
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: t('error_password_change'),
          life: 3000,
        });
      }
    });
};
</script>

<template>
  <Dialog
    :header="t('title')"
    :visible="visible"
    @update:visible="emit('update:visible', $event)"
    style="width: 360px"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
    @hide="resetDialog"
  >
    <div class="flex flex-col gap-2">
      <label class="block w-full">
        {{ t('old_password_label') }}
        <Password
          class="my-2 w-full"
          :placeholder="t('enter_password_placeholder')"
          name="password"
          v-model="password"
          autocomplete="current-password"
          toggle-mask
          :feedback="false"
          :input-props="{ size: 'large', class: 'w-full' }"
        />
      </label>
      <label class="block w-full">
        {{ t('new_password_label') }}
        <Password
          class="my-2 w-full"
          :placeholder="t('enter_password_placeholder')"
          name="password"
          v-model="newPassword"
          toggle-mask
          :prompt-label="t('password_hint')"
          :weak-label="t('password_weak')"
          :medium-label="t('password_medium')"
          :strong-label="t('password_strong')"
          autocomplete="new-password"
          :input-props="{ size: 'large', class: 'w-full' }"
        >
          <template #footer>
            <ul class="pl-2 ml-2 mt-3 mb-0" style="line-height: 1.5">
              <li>{{ t('password_rules.0') }}</li>
            </ul>
          </template>
        </Password>
      </label>
    </div>

    <template #footer>
      <Button
        @click="resetDialog"
        :label="t('cancel')"
        icon="pi pi-times"
        text
      />
      <Button
        @click="saveNewPassword"
        :label="t('save')"
        icon="pi pi-check"
        autofocus
        :loading="isLoading"
        :disabled="!isValid"
      />
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Change password
  old_password_label: Old password
  new_password_label: New password
  enter_password_placeholder: Enter password
  password_hint: Enter a password
  password_weak: Weak password
  password_medium: Medium password
  password_strong: Strong password
  password_rules:
    - Minimum 8 characters
  cancel: Cancel
  save: Save
  success_password_updating: Password updated successfully
  error_wrong_old_password: Wrong old password
  error_password_change: Password change error

ru:
  title: Изменение пароля
  old_password_label: Старый пароль
  new_password_label: Новый пароль
  enter_password_placeholder: Введите пароль
  password_hint: Введите пароль
  password_weak: Слабый пароль
  password_medium: Средний пароль
  password_strong: Сильный пароль
  password_rules:
    - Минимум 8 символов
  cancel: Отменить
  save: Сохранить
  success_password_updating: Пароль успешно обновлен
  error_wrong_old_password: Неверный старый пароль
  error_password_change: Ошибка смены пароля

de:
  title: Ändern Sie Ihr Passwort
  old_password_label: Altes Passwort
  new_password_label: Neues Passwort
  enter_password_placeholder: Geben Sie Ihr Passwort ein
  password_hint: Geben Sie Ihr Passwort ein
  password_weak: Schwaches Passwort
  password_medium: Durchschnittliches Passwort
  password_strong: Starkes Passwort
  password_rules:
    - Mindestens 8 Zeichen
  cancel: Stornieren
  save: Speichern
  success_password_updating: Passwort erfolgreich aktualisiert
  error_wrong_old_password: Ungültiges altes Passwort
  error_password_change: Fehler bei der Passwortänderung

es:
  title: Cambiando tu contraseña
  old_password_label: Contraseña anterior
  new_password_label: Nueva contraseña
  enter_password_placeholder: Introduce tu contraseña
  password_hint: Introduce tu contraseña
  password_weak: Contraseña débil
  password_medium: Contraseña promedio
  password_strong: Contraseña segura
  password_rules:
    - Mínimo 8 caracteres
  cancel: Cancelar
  save: Ahorrar
  success_password_updating: Contraseña actualizada exitosamente
  error_wrong_old_password: Contraseña antigua no válida
  error_password_change: Error de cambio de contraseña

fr:
  title: Changer votre mot de passe
  old_password_label: Ancien mot de passe
  new_password_label: Nouveau mot de passe
  enter_password_placeholder: Entrez votre mot de passe
  password_hint: Entrez votre mot de passe
  password_weak: Mot de passe faible
  password_medium: Mot de passe moyen
  password_strong: Mot de passe fort
  password_rules:
    - Minimum 8 caractères
  cancel: Annuler
  save: Sauvegarder
  success_password_updating: Mot de passe mis à jour avec succès
  error_wrong_old_password: Ancien mot de passe invalide
  error_password_change: Erreur de changement de mot de passe
</i18n>
