<script setup lang="ts">
const route = useRoute();
const { t, locale } = useI18n();
const config = useRuntimeConfig();
const store = useMainStore();

const isMounted = ref(false);
const isCypress = useCookie<boolean>('cypress');

const canonical = computed(() => `${config.public.origin}${route.path}`);

onMounted(() => {
  isMounted.value = true;
});
</script>

<template>
  <Html
    :lang="locale"
    :class="{ disableAnimation: isCypress, mounted: isMounted }"
  />
  <Head>
    <Title>{{ t('meta.basic.title') }}</Title>
    <Meta name="description" :content="t('meta.basic.description')" />
    <Meta name="keywords" :content="t('meta.basic.keywords')" />

    <Meta property="og:site_name" content="Sex Play" />
    <Meta property="og:title" :content="t('meta.basic.title')" />
    <Meta property="og:description" :content="t('meta.basic.description')" />
    <Meta property="og:image" :content="`${config.public.origin}/og_image.jpeg`" />
    <Meta property="og:image:width" content="1200" />
    <Meta property="og:image:height" content="630" />
    <Meta property="og:type" content="website" />
    <Meta name="twitter:card" content="summary_large_image" />

    <Link rel="canonical" :href="canonical" />
    <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
    <Meta name="msapplication-TileColor" content="#000000" />
    <Meta name="theme-color" content="#20262e" />
  </Head>
  <Body class="p-dark sex-play-theme" />

  <NuxtLoadingIndicator />
  <NuxtRouteAnnouncer />
  <VitePwaManifest />
  <SystemI18nSetup />
  <SystemSchemaOrgSetup />

  <div class="relative flex flex-col min-h-100svh">
    <LayoutHeadMenu />

    <NuxtPage />
  </div>

  <ClientOnly>
    <Toast position="bottom-center" />
    <ConfirmDialog />

    <LazyDialogSubscription />
    <SystemScriptSetup />

    <template v-if="store.loaded">
      <LazyDialogPairedGameInvited />
      <LazySystemAuthorizedSetup />
    </template>

    <div id="captcha-container" :data-hl="locale" />
  </ClientOnly>
</template>

<i18n src="~/locales/meta.yaml"></i18n>

<style lang="scss">
@use 'assets/styles/base.scss';
@use 'assets/styles/components.scss';
@use 'assets/styles/animations.scss';

:root {
  --header-height: 64px;

  --v-onboarding-overlay-z: 999999998;
  --v-onboarding-step-z: 999999999;
}

.disableAnimation * {
  animation-duration: 0s !important;
  transition-duration: 0s !important;
}
</style>
