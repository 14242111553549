<script lang="ts" setup>
const props = defineProps<{
  image: string;
}>();

const getImage = useImage();

const url = computed(() => `url(${getImage(props.image, { format: 'avif' })})`);
</script>

<template>
  <div class="basePageBackgroundImage absolute top-0 right-0 bottom-0 left-0" />
</template>

<style lang="scss">
.basePageBackgroundImage {
  background-image: v-bind(url);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100dvh;
}
</style>
