<script lang="ts" setup>
const infoStore = useInfoStore();
const router = useRouter();
const { t } = useI18n();

const visible = ref(false);

const acceptPairedGame = () => {
  infoStore.acceptPairedGame();
  visible.value = false;
  router.push({
    path: '/game',
    query: { mode: 'paired' },
  });
};

const discardPairedGame = () => {
  infoStore.discardPairedGame();
  visible.value = false;
};

watch(
  () => infoStore.pairedGame,
  (v, prev) => {
    if (v && v.status === PairedGameStatus.invited && !v.leaded)
      visible.value = true;
    else if (prev && !v) visible.value = false;
  },
  { immediate: true },
);
</script>

<template>
  <Dialog
    style="width: 520px"
    dismissable-mask
    :base-z-index="6"
    v-model:visible="visible"
    :draggable="false"
    class="max-w-full"
  >
    <template #header>{{ t('title') }}</template>

    <div class="flex flex-col items-center">
      <p class="m-0 leading-6">{{ t('description') }}</p>
    </div>

    <template #footer>
      <div class="flex gap-2">
        <Button
          @click="discardPairedGame"
          :label="t('decline')"
          icon="pi pi-ban"
          text
          class="ml-auto"
        />
        <Button
          @click="acceptPairedGame"
          :label="t('accept')"
          rounded
          size="large"
          class="px-6"
        />
      </div>
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Invitation to play together
  description: You have been invited to play together from two devices. Accept the invitation to start the game.
  decline: Reject
  accept: Start

ru:
  title: Приглашение в совместную игру
  description: Вас пригласили в совместную игру с двух устройств. Примите приглашение для начала игры.
  decline: Отклонить
  accept: Начать

de:
  title: Einladung zum gemeinsamen Spielen
  description: Sie wurden eingeladen, auf zwei Geräten zusammen zu spielen. Nehmen Sie die Einladung an, um das Spiel zu starten.
  decline: Ablehnen
  accept: Beginnen

es:
  title: Invitación a jugar juntos.
  description: Te han invitado a jugar juntos desde dos dispositivos. Acepta la invitación para iniciar el juego.
  decline: Rechazar
  accept: Comenzar

fr:
  title: Invitation à jouer ensemble
  description: Vous avez été invité à jouer ensemble depuis deux appareils. Acceptez l'invitation pour démarrer le jeu.
  decline: Rejeter
  accept: Commencer
</i18n>
