export default defineNuxtPlugin((nuxtApp) => {
  const checkVisible = (target: Element, margin = 0) => {
    const targetPosition = {
      top: window.scrollY + target.getBoundingClientRect().top,
      bottom: window.scrollY + target.getBoundingClientRect().bottom,
    };
    const windowPosition = {
      top: window.scrollY,
      bottom: window.scrollY + document.documentElement.clientHeight,
    };

    return (
      targetPosition.bottom + margin > windowPosition.top &&
      targetPosition.top - margin < windowPosition.bottom
    );
  };

  const weakMap = new WeakMap<Element, () => void>();

  const initElement = (el: HTMLElement) => {
    // Получаем JSON-опции
    let options: {
      margin?: number;
      delay?: number;
      noDelayOnMobile?: number;
    } = {};

    const optionsAttr = el.getAttribute('data-show-on-visible');
    if (optionsAttr) {
      try {
        options = JSON.parse(optionsAttr);
      } catch (e) {
        console.warn('Invalid JSON in data-show-on-visible:', e, optionsAttr);
      }
    }

    const margin = options.margin || 0;
    const noDelayOnMobile = options.noDelayOnMobile || 0;
    const delay = options.delay || 0;

    const init = () => {
      el.style.transform = 'translateY(100px)';
      el.style.opacity = '0';
      el.style.transition = 'opacity 0.5s, transform 0.5s';

      const show = () => {
        if (checkVisible(el, margin)) {
          setTimeout(
            () => {
              el.style.transform = '';
              el.style.opacity = '';
              window.removeEventListener('scroll', weakMap.get(el)!);
              weakMap.delete(el);
            },
            ((!noDelayOnMobile || window.innerWidth >= noDelayOnMobile) &&
              delay) ||
              0,
          );
        }
      };

      weakMap.set(el, show);
      window.addEventListener('scroll', show);
      // Небольшая задержка, чтобы проверить видимость после загрузки
      setTimeout(() => {
        if (weakMap.get(el)) weakMap.get(el)!();
      }, 500);
    };

    if (!checkVisible(el, margin)) init();
  };

  const applyAnimationLogic = () => {
    const elements = document.querySelectorAll('[data-show-on-visible]');
    elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        // Удаляем старые обработчики, если остались (например при быстром переходе)
        if (weakMap.has(el)) {
          const oldShow = weakMap.get(el)!;
          window.removeEventListener('scroll', oldShow);
          weakMap.delete(el);
        }
        initElement(el);
      }
    });
  };

  // page:transition:finish
  // Инициализируем после монтирования и при каждой смене роутера
  nuxtApp.hook('app:mounted', () => {
    applyAnimationLogic();
  });

  // Если вы используете Nuxt 3 router, вы можете подписаться на изменения маршрутов
  const router = useRouter();
  router.afterEach(() => {
    // Даем немного времени DOM обновиться
    setTimeout(() => {
      applyAnimationLogic();
    }, 100);
  });
});
