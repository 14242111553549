<script setup lang="ts">
import type { QuestionWithAnswersType } from '~/types/questions';

const props = defineProps<{
  question: QuestionWithAnswersType;
  archived?: boolean;
  actions?: boolean;
  hoverable?: boolean;
  chat?: boolean;
}>();
const emit = defineEmits<{
  (event: 'delete'): void;
  (event: 'click'): void;
  (event: 'chat'): void;
}>();

const { t } = useI18n();

const menu = ref<{ toggle: (event: Event) => void }>();

const archive = () => {
  QuestionAPI.archiveAnswer({ params: { questionId: props.question.id } }).then(
    () => emit('delete'),
  );
};

const restore = () => {
  QuestionAPI.restoreAnswer({ params: { questionId: props.question.id } }).then(
    () => emit('delete'),
  );
};

const options = computed(() => [
  props.archived
    ? { label: t('restore'), command: restore }
    : { label: t('archive'), command: archive },
]);

const toggle = (event: Event) => menu.value?.toggle(event);
</script>

<template>
  <Card
    @click="emit('click')"
    :class="{
      'hover:shadow-lg hover:-translate-y-0.5 cursor-pointer transition':
        hoverable,
    }"
    :pt="{ body: 'h-full !p-4 lg:!p-5', content: 'h-full flex flex-col' }"
  >
    <template #content>
      <div class="mb-3 flex items-start">
        <div class="flex-1 mr-2">
          <p class="text-lg font-medium m-0">
            {{ question.question }}
          </p>
          <template v-if="question.pairedQuestion">
            <Divider class="!my-2" />
            <p class="font-medium text-lg m-0">
              {{ question.pairedQuestion }}
            </p>
          </template>
        </div>
        <template v-if="actions">
          <Button
            icon="pi pi-ellipsis-v"
            text
            rounded
            size="small"
            class="ml-auto"
            @click="toggle"
          />
          <Menu ref="menu" :model="options" popup />
        </template>
      </div>

      <div class="flex justify-between text-lg md:text-sm mt-auto">
        <span>
          <span class="text-slate-300">{{ t('you') }}:</span>
          <br class="xxl:hidden" />
          <BaseAnswerText :answer="question.userAnswer.answer" />
          <br class="md:hidden" />
          <template v-if="question.userAnswer.pairedAnswer">
            <span class="hidden md:inline-block mx-1">/</span>
            <BaseAnswerText :answer="question.userAnswer.pairedAnswer" />
          </template>
        </span>
        <span>
          <span class="text-slate-300">{{ t('partner') }}:</span>
          <br class="xxl:hidden" />
          <BaseAnswerText :answer="question.partnerAnswer.answer" />
          <br class="md:hidden" />
          <template v-if="question.partnerAnswer.pairedAnswer">
            <span class="hidden md:inline-block mx-1">/</span>
            <BaseAnswerText :answer="question.partnerAnswer.pairedAnswer" />
          </template>
        </span>
      </div>
    </template>

    <template #footer v-if="chat">
      <Button
        @click="emit('chat')"
        label="Обсудить идею"
        outlined
        size="small"
        class="w-full"
      />
    </template>
  </Card>
</template>

<i18n>
en:
  you: You
  partner: Partner
  restore: Restore
  archive: Archive

ru:
  you: Вы
  partner: Партнер
  restore: Восстановить
  archive: Архивировать

de:
  you: Du
  partner: Partner
  restore: Wiederherstellen
  archive: Archiv

es:
  you: Tú
  partner: Pareja
  restore: Restaurar
  archive: Archivo

fr:
  you: Toi
  partner: Partenaire
  restore: Restaurer
  archive: Archive
</i18n>
