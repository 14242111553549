<script lang="ts" setup>
import * as Sentry from '@sentry/vue';

const nuxtApp = useNuxtApp();

onMounted(() => {
  if (import.meta.dev) return;

  const config = useRuntimeConfig();
  const router = useRouter();

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    environment: config.public.projectEnvironment,
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn as string,
    integrations: [
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
          hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        },
      }),
      Sentry.browserSessionIntegration(),
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path',
      }),
    ],
    tracePropagationTargets: ['localhost', /^\//, config.public.host],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  });

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    Sentry.withScope((scope) => {
      scope.setExtra('context', context);
      Sentry.captureException(err);
    });
  };

  nuxtApp.hook('app:error', (err) => {
    Sentry.captureException(err);
  });
});
</script>

<template>
  <slot />
</template>
