<script lang="ts" setup>
import type { StepEntity, VOnboardingWrapperOptions } from 'v-onboarding';
import {
  useVOnboarding,
  VOnboardingStep,
  VOnboardingWrapper,
} from 'v-onboarding';

import { FeatureVariants } from '~/stores/feature';

const OPTIONS = {
  labels: {
    previousButton: 'Назад',
    nextButton: 'Далее',
    finishButton: 'Играть❤️‍🔥',
  },
  hideNextStepDuringHook: true,
} satisfies VOnboardingWrapperOptions;

const wrapper = ref();

const featureStore = useFeatureStore();
const mainStore = useMainStore();
const { t } = useI18n();
const { start, finish } = useVOnboarding(wrapper);

const activeOnboarding = ref<StepEntity[]>();

const sleep = (duration: number | (() => unknown)) =>
  new Promise<void>((resolve, reject) => {
    if (typeof duration === 'number') setTimeout(resolve, duration);
    else {
      (async () => {
        let timer = 0;
        while (!duration()) {
          await sleep(50);
          timer += 50;
          if (timer > 10000) {
            finish();
            reject();
            break;
          }
        }
        resolve();
      })();
    }
  });

const SEX_STATUS_WIDGET_TOUR: StepEntity[] = [
  {
    attachTo: { element: '[data-onboarding=sex-status-widget-opener]' },
    content: {
      title: t('sex_status.title'),
      description: t('sex_status.list.0'),
    },
    on: {
      afterStep: () => {
        mainStore.sexStatusWidgetDialogVisible = true;
      },
    },
  },
  {
    attachTo: { element: '[data-onboarding=sex-status-widget-toggle]' },
    content: {
      title: t('sex_status.title'),
      description: t('sex_status.list.1'),
    },
    on: {
      beforeStep: () => sleep(400),
    },
    options: {
      overlay: {
        padding: {
          top: 8,
          bottom: 8,
        },
      },
    },
  },
  {
    attachTo: { element: '[data-onboarding=sex-status-widget-idea]' },
    content: {
      title: t('sex_status.title'),
      description: t('sex_status.list.2'),
    },
    on: {
      afterStep: () => {
        mainStore.sexStatusIdeaDialogVisible = true;
      },
    },
  },
  {
    attachTo: { element: '[data-onboarding=sex-status-widget-input]' },
    content: {
      title: t('sex_status.title'),
      description: t('sex_status.list.3'),
    },
    on: {
      beforeStep: async () => {
        await sleep(
          () =>
            !document.querySelector(
              '[data-onboarding=sex-status-widget-loader]',
            ),
        );
        await sleep(700);
      },
    },
  },
  {
    attachTo: { element: '[data-onboarding=sex-status-widget-list]' },
    content: {
      title: t('sex_status.title'),
      description: t('sex_status.list.4'),
    },
    options: {
      scrollToStep: {
        enabled: false,
      },
    },
    on: {
      afterStep: () => {
        mainStore.sexStatusWidgetDialogVisible = false;
        mainStore.sexStatusIdeaDialogVisible = false;

        featureStore.completeOnboarding(FeatureVariants.SexStatusWidget);
        mainStore.isOnboardingProcessed = false;
      },
    },
  },
];

const onboardings = computed(() => [
  {
    data: SEX_STATUS_WIDGET_TOUR,
    key: FeatureVariants.SexStatusWidget,
  },
]);

onMounted(() => {
  const onboarding = onboardings.value.find((el) =>
    featureStore.onboardings.includes(el.key),
  );

  if (onboarding) {
    activeOnboarding.value = onboarding.data;
    mainStore.isOnboardingProcessed = true;
    start();
  }
});
</script>

<template>
  <VOnboardingWrapper
    ref="wrapper"
    :options="OPTIONS"
    :steps="SEX_STATUS_WIDGET_TOUR"
  >
    <template #default="{ next, step, isLast }">
      <VOnboardingStep>
        <Card
          class="m-2 neon-border max-w-80"
          :pt="{ body: 'p-4', content: 'text-sm' }"
        >
          <template v-if="step.content.title" #title>
            {{ step.content.title }}
          </template>
          <template v-if="step.content.description" #content>
            {{ step.content.description }}
          </template>
          <template #footer>
            <Button
              @click="next"
              :label="OPTIONS.labels[isLast ? 'finishButton' : 'nextButton']"
              text
              size="small"
              class="ml-auto flex"
            />
          </template>
        </Card>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<i18n>
ru:
  sex_status:
    title: Хотите заняться сексом?
    list:
      - Вы можете предложить партнеру заняться сексом всего в один клик! Но это далеко не все😍
      - Кликните по переключателю или по огонечку слева и ваш партнер узнает о вашем желании🥰 Главное попросите его
        включить уведомления в профиле.
      - Также вы можете отправить что-то конкретное, любую идею, которую хотите реализовать сегодня - конкретное
        предложение возбудит любого😉
      - Напишите любое ваше желание и не стесняйтесь в красках и описании, пусть ваш партнер захочет воплотить это
        вместе с вами🔥
      - А если устали или нет идей, то воспользуйтесь готовыми шпаргалками - это список желаний, которые уже совпали у
        вас с партнером после игры в интересы⭐

en:
  sex_status:
    title: Do you want to have sex?
    list:
      - You can invite your partner to have sex in just one click. But that's not all😍
      - Click on the switch or the light on the left and your partner will know about your desire🥰 Ask them to include
        notifications in their profile.
      - You can also send something specific, any idea you want to implement today - a specific offer will excite anyone😉
      - Write any of your desires and do not hesitate in the colors and description, let your partner want to embody it
        with you🔥
      - And if you are tired or no ideas, then use ready-made cheat sheets - this is a list of wishes that have already
        coincided with your partner after playing in interests⭐

es:
  sex_status:
    title: ¿Quieres tener sexo?
    list:
      - Puedes invitar a tu pareja a tener sexo en un solo clic. Pero eso no es todo😍
      - Haga clic en el interruptor o en la luz de la izquierda y su pareja sabrá sobre su deseo🥰 Pídales que incluyan
        notificaciones en su perfil.
      - También puede enviar algo específico, cualquier idea que desee implementar hoy - una oferta específica excitará
        a cualquiera😉
      - Escribe cualquiera de tus deseos y no dudes en los colores y la descripción, deja que tu pareja desee encarnarlo
        contigo🔥
      - Y si estás cansado o sin ideas, entonces usa hojas de trampolín listas - esta es una lista de deseos que ya han
        coincidido con tu pareja después de jugar en intereses⭐

de:
  sex_status:
    title: Willst du Sex haben?
    list:
      - Sie können Ihren Partner einladen, Sex in nur einem Klick zu haben. Aber das ist nicht alles😍
      - Klicken Sie auf den Schalter oder das Licht auf der linken Seite und Ihr Partner wird über Ihren Wunsch wissen🥰
        Bitten Sie sie, Benachrichtigungen in ihr Profil aufzunehmen.
      - Sie können auch etwas Bestimmtes senden, jede Idee, die Sie heute umsetzen möchten - ein spezifisches Angebot
        wird jeden begeistern😉
      - Schreiben Sie Ihre Wünsche und zögern Sie nicht in den Farben und Beschreibung, lassen Sie Ihren Partner es mit
        Ihnen verkörpern möchten🔥
      - Und wenn Sie müde sind oder keine Ideen, dann verwenden Sie fertige Cheat-Blatt - dies ist eine Liste von
        Wünschen, die bereits mit Ihrem Partner nach dem Spielen im Interesse zusammengefallen sind⭐

fr:
  sex_status:
    title: Tu veux coucher ?
    list:
      - Vous pouvez inviter votre partenaire à coucher en un seul clic. Mais ce n'est pas tout😍
      - Cliquez sur l'interrupteur ou la lumière sur la gauche et votre partenaire sera au courant de votre désir🥰
        Demandez-leur d'inclure des notifications dans leur profil.
      - Vous pouvez également envoyer quelque chose de spécifique, n'importe quelle idée que vous voulez implémenter
        aujourd'hui - une offre spécifique va exciter n'importe qui
      - Écrivez n'importe lequel de vos désirs et n'hésitez pas dans les couleurs et la description, laissez votre
        partenaire vouloir l'incarner avec vous🔥
      - Et si vous êtes fatigué ou pas d'idées, alors utilisez des feuilles de triche prêtes à l'emploi - c'est une
        liste de souhaits qui ont déjà coïncidé avec votre partenaire après avoir joué dans des intérêts⭐
</i18n>
