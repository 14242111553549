<script lang="ts" setup>
const store = useMainStore();
const { t } = useI18n();
</script>

<template>
  <Transition name="sexStatusButton">
    <Button
      v-if="store.isAuthorized && store.user.pairedUser"
      icon="pi"
      rounded
      text
      size="large"
      class="sexStatusButton p-0"
      data-onboarding="sex-status-widget-opener"
      :class="{ 'neon-border': store.user.pairedUser.wantSexMessage }"
      tabindex="0"
      :aria-label="t('open_arial_label')"
      @click="store.sexStatusWidgetDialogVisible = true"
    >
      <BaseFireIcon
        :active="store.user.wantSex"
        class="sexStatusButton__icon"
      />
      <BaseFireIcon
        :active="store.user.pairedUser?.wantSex"
        class="sexStatusButton__icon"
      />
    </Button>
  </Transition>
</template>

<i18n>
en:
  open_arial_label: Find out the desire of a partner's sex

ru:
  open_arial_label: Узнать желание секса партнера

de:
  open_arial_label: Finden Sie heraus, welches Verlangen Ihr Partner nach Sex hat

es:
  open_arial_label: Descubra el deseo sexual de su pareja

fr:
  open_arial_label: Découvrez le désir sexuel de votre partenaire
</i18n>

<style lang="scss">
.sexStatusButton {
  position: relative;
  width: 3.6rem !important;
  height: 3.6rem !important;

  &__icon {
    position: absolute;
    left: 50%;

    &:first-of-type {
      transform: translateX(calc(-50% - 7px));
    }

    &:last-of-type {
      transform: translateX(calc(-50% + 7px));
    }
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
