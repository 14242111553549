export enum FeatureVariants {
  SexStatusWidget = 'sex_status_widget',
}

export const useFeatureStore = defineStore('feature', () => {
  const userStore = useUserStore();

  const completedOnboardings = ref<string[]>();

  const isSexStatusWidgetInitialized = ref(false);

  const conditions = computed(() => [
    {
      key: FeatureVariants.SexStatusWidget,
      rule: isSexStatusWidgetInitialized.value && userStore.user?.pairedUserId,
    },
  ]);

  const onboardings = computed(() => {
    if (!completedOnboardings.value) return [];

    return conditions.value.reduce<FeatureVariants[]>((acc, el) => {
      if (el.rule && !completedOnboardings.value!.includes(el.key))
        acc.push(el.key);

      return acc;
    }, []);
  });

  const loadOnboarding = () => {
    ProfileAPI.getOnboardings().then((data) => {
      completedOnboardings.value = data.map((el) => el.key);
    });
  };

  const completeOnboarding = (key: FeatureVariants) => {
    completedOnboardings.value?.push(key);

    ProfileAPI.saveOnboarding({ body: { key } });
  };

  watch(
    () => userStore.loaded,
    (v) => {
      if (import.meta.server || !v) return;

      loadOnboarding();
    },
    { immediate: true },
  );

  return { onboardings, isSexStatusWidgetInitialized, completeOnboarding };
});
