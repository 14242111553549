<script lang="ts" setup>
import { startRegistration } from '@simplewebauthn/browser';
// TODO: возможно все же перенести на сервер - но тут есть проверка PWA
import { UAParser } from 'ua-parser-js';

import type { MenuItem } from '#types';
import type { PasskeyType } from '~/types/users';

const visible = defineModel<boolean>('visible');

const config = useRuntimeConfig();
const toast = useCustomToast();
const confirm = useCustomConfirm();
const { t } = useI18n();

const loading = ref(false);
const selectedPasskey = ref<PasskeyType>();
const list = ref<PasskeyType[]>([]);
const isInstructionDialogVisible = ref(false);

const menu = ref();

const generateDeviceName = () => {
  const parser = new UAParser();

  const result = parser.getResult();

  return `${result.os.name} (${result.browser.name})`;
};

const setup = async () => {
  try {
    loading.value = true;

    const encoded = await ProfileAPI.passkeyRegister();
    const options = JSON.parse(atob(encoded.content));

    const registrationResponse = await startRegistration({
      optionsJSON: options,
    });

    const passkey = await ProfileAPI.passkeyRegisterVerify({
      body: {
        name: generateDeviceName(),
        content: btoa(JSON.stringify(registrationResponse)),
      },
    });

    list.value.unshift(passkey);

    toast.add({
      summary: t('message.setup_success'),
      severity: 'success',
      life: 3000,
    });
  } catch {
    toast.add({
      summary: t('message.setup_failed'),
      severity: 'error',
      life: 5000,
    });
  } finally {
    loading.value = false;
  }
};

const openMenu = (event: Event, item: PasskeyType) => {
  selectedPasskey.value = item;
  menu.value.show(event);
};

const parseDate = (date: string) =>
  new Intl.DateTimeFormat(config.public.locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(new Date(date));

const menuItems = computed<MenuItem[]>(() => [
  {
    label: t('message.delete'),
    icon: 'pi pi-trash',
    command: () => {
      if (!selectedPasskey.value) return;
      const passkeyId = selectedPasskey.value.id;

      confirm.require({
        header: t('message.delete_title'),
        message: t('message.delete_description'),
        accept: () => {
          ProfileAPI.passkeyDisable({ params: { passkeyId } })
            .then(() => {
              list.value = list.value.filter((item) => item.id !== passkeyId);
              toast.add({
                summary: t('message.delete_success'),
                severity: 'success',
                life: 3000,
              });
            })
            .catch(() => {
              toast.add({
                summary: t('message.delete_failed'),
                severity: 'error',
                life: 5000,
              });
            });
        },
        acceptLabel: t('message.delete'),
        rejectLabel: t('message.cancel'),
      });
    },
  },
]);

onMounted(() => {
  ProfileAPI.passkeyList().then((data) => {
    list.value = data;
  });
});
</script>

<template>
  <Dialog
    v-model:visible="visible"
    dismissable-mask
    :draggable="false"
    modal
    class="w-104"
  >
    <template #header>
      <div class="flex gap-2 items-center">
        <span class="p-dialog-title">
          {{ t(list.length ? 'title_exists' : 'title') }}
        </span>

        <Button
          @click="isInstructionDialogVisible = true"
          icon="pi pi-question-circle"
          text
          rounded
          class="w-1.5rem h-1.5rem"
        />
      </div>
    </template>

    <!-- TODO: надо добавить помощника по вопросу -->
    <div v-if="list.length" class="flex flex-col">
      <div
        v-for="item in list"
        :key="item.id"
        class="flex text-sm items-center gap-2 py-2"
      >
        <div
          class="flex flex-col gap-1 text-sm whitespace-nowrap overflow-hidden"
        >
          <span class="overflow-hidden text-ellipsis">{{ item.name }}</span>
          <span class="opacity-70 overflow-hidden text-ellipsis">
            {{ t('last_used', { date: parseDate(item.lastUsedAt) }) }}
          </span>
        </div>
        <Button
          @click="openMenu($event, item)"
          icon="pi pi-ellipsis-v"
          text
          rounded
          class="ml-auto"
        />
      </div>

      <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
    </div>
    <div v-else class="text-sm text-center py-3">
      {{ t('empty') }}
    </div>

    <Divider />
    <Button
      @click="setup"
      :label="t('setup')"
      :loading="loading"
      outlined
      class="w-full"
    />
  </Dialog>

  <Dialog
    :header="t('title')"
    v-model:visible="isInstructionDialogVisible"
    dismissable-mask
    :draggable="false"
    modal
    class="w-104"
  >
    <p class="whitespace-pre-wrap m-0 text-sm">{{ t('instruction') }}</p>

    <template #footer>
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="isInstructionDialogVisible = false"
        text
      />
    </template>
  </Dialog>
</template>

<i18n>
ru:
  title: Вход по биометрии
  title_exists: Ваши ключи входа
  setup: Добавить ключ
  last_used: 'Использовано: {date}'
  empty: Вы еще не добавили ни одного ключа
  close: Закрыть
  message:
    setup_success: Вход по биометрии успешно настроен!
    setup_failed: Во время настройки входа по биометрии произошла ошибка
    delete_title: Удаление ключа
    delete_description: Вы уверены, что хотите удалить этот ключ?
    delete_success: Ключ успешно удален
    delete_failed: Удалить
    delete: Удалить
    cancel: Отменить
  instruction: >
    Усиленный способ входа, называемый PassKey, является крайне безопасным и надежным способом авторизации в своем
    аккаунте. Чаще всего используются биометрические сканеры, такие как сканер отпечатка пальца, лидар или иные
    доступные вашему устройству инструменты.


    Подключение этого способа входа оставит доступным авторизацию по логину и паролю. Хотя для большей безопасности
    вашего аккаунта мы рекомендуем для входа по паролю также подключить двухфакторную аутентификацию. Вход через PassKey
    является самодостаточным и не будет спрашивать с вас дополнительные данные для входа.


    Если ваше устройство поддерживает PassKey, то рекомендуем подключать его всегда для вашего аккаунта.

en:
  title: Entrance by biometrics
  title_exists: Your entrance keys.
  setup: Add the key
  last_used: 'Used: {date}'
  empty: You haven't added a single key yet.
  message:
    setup_success: Entrance to biometrics successfully configured!
    setup_failed: An error occurred during the biometric input setting
    delete_title: Removal of the key
    delete_description: Are you sure you want to delete this key?
    delete_success: Key successfully removed
    delete_failed: Delete
    delete: Delete
    cancel: Cancel.
  close: Close.
  instruction: >-
    A reinforced login method called PassKey is an extremely secure and reliable way to log in to your account. Most
    often, biometric scanners are used, such as a fingerprint scanner, lidar or other tools available to your device.


    Connecting this login method will leave login and password authorization available. Although for greater security of
    your account, we recommend that you also connect two-factor authentication to log in by password. PassKey login is
    self-sufficient and will not ask you for additional login details.


    If your device supports PassKey, we recommend that you always connect it to your account.

es:
  title: Entrada por biometría
  title_exists: Tus llaves de entrada.
  setup: Añadir la llave
  last_used: 'Usado: {date}'
  empty: Aún no has añadido una sola llave.
  message:
    setup_success: Entrada a biometría configurada con éxito!
    setup_failed: Se produjo un error durante la configuración de entrada biométrica
    delete_title: Eliminación de la llave
    delete_description: ¿Seguro que quieres borrar esta llave?
    delete_success: Llave con éxito
    delete_failed: Suprimir
    delete: Suprimir
    cancel: Cancelar.
  close: Cerca.
  instruction: >-
    Un método de inicio de sesión reforzado llamado PassKey es una forma extremadamente segura y confiable de iniciar
    sesión en su cuenta. La mayoría de las veces se utilizan escáneres biométricos, como un escáner de huellas
    dactilares, lidar u otras herramientas disponibles para su dispositivo.


    Conectar este método de inicio de sesión dejará disponible la autorización de inicio de sesión y contraseña. Aunque
    para una mayor seguridad de su cuenta, recomendamos que también conecte la autenticación de dos factores para
    iniciar sesión por contraseña. PassKey login es autosuficiente y no te pedirá más detalles de inicio de sesión.


    Si el dispositivo soporta PassKey, te recomendamos que siempre lo conectes a tu cuenta.

de:
  title: Eingang durch Biometrie
  title_exists: Ihre Eingangsschlüssel.
  setup: Den Schlüssel hinzufügen
  last_used: 'Verwendet: {date}'
  empty: Du hast noch keinen einzigen Schlüssel hinzugefügt.
  message:
    setup_success: Eingang zu biometrischen Daten erfolgreich konfiguriert!
    setup_failed: Während der biometrischen Eingabeeinstellung ist ein Fehler aufgetreten
    delete_title: Entfernung des Schlüssels
    delete_description: Sind Sie sicher, dass Sie diesen Schlüssel löschen möchten?
    delete_success: Schlüssel erfolgreich entfernt
    delete_failed: Löschen
    delete: Löschen
    cancel: Abbrechen.
  close: Fast.
  instruction: >-
    Eine verstärkte Login-Methode namens PassKey ist eine äußerst sichere und zuverlässige Möglichkeit, sich in Ihrem
    Konto anzumelden. Meist werden biometrische Scanner eingesetzt, wie z.B. Fingerabdruckscanner, Lidar oder andere
    Werkzeuge, die für Ihr Gerät verfügbar sind.


    Das Anschließen dieser Anmeldemethode lässt Login und Passwortberechtigung verfügbar. Obwohl für mehr Sicherheit
    Ihres Kontos, empfehlen wir Ihnen, auch Zwei-Faktor-Authentifizierung mit Passwort zu verbinden. PassKey Login ist
    autark und wird Sie nicht um zusätzliche Login-Details bitten.


    Wenn Ihr Gerät unterstützt PassKey, wir empfehlen, dass Sie es immer mit Ihrem Konto verbinden.

fr:
  title: Entrée par biométrie
  title_exists: Vos clés d'entrée.
  setup: Ajouter la clé
  last_used: 'Utilisé : {date}'
  empty: Vous n'avez pas encore ajouté de clé.
  message:
    setup_success: Entrée à la biométrie configurée avec succès!
    setup_failed: Une erreur s'est produite lors du réglage de l'entrée biométrique
    delete_title: Suppression de la clé
    delete_description: Voulez-vous vraiment supprimer cette clé ?
    delete_success: La clé a été retirée avec succès
    delete_failed: Supprimer
    delete: Supprimer
    cancel: Annuler.
  close: Presque.
  instruction: >-
    Une méthode de connexion renforcée appelée PassKey est un moyen extrêmement sûr et fiable de se connecter à votre
    compte. Le plus souvent, des scanners biométriques sont utilisés, comme un scanner d'empreintes digitales, un lidar
    ou d'autres outils disponibles pour votre appareil.


    Connecter cette méthode de connexion laissera la connexion et l'autorisation de mot de passe disponibles. Bien que
    pour une plus grande sécurité de votre compte, nous vous recommandons également de connecter l'authentification à
    deux facteurs pour vous connecter par mot de passe. PassKey login est auto-suffisant et ne vous demandera pas de
    détails de connexion supplémentaires.


    Si votre appareil supporte PassKey, nous vous recommandons de toujours le connecter à votre compte.
</i18n>
