<script lang="ts" setup>
const store = useMainStore();
const featureStore = useFeatureStore();
const firebase = useFirebaseStore();

watch(
  () => store.loaded,
  (v) => {
    if (v) firebase.request();
  },
  { immediate: true },
);

onMounted(() => {
  firebase.init();
});
</script>

<template>
  <div class="flex flex-col min-h-100svh">
    <NuxtPage />

    <ClientOnly>
      <LazySystemSentrySetup />
      <LazyBaseOnboarding v-if="featureStore.onboardings.length" />
    </ClientOnly>
  </div>
</template>
