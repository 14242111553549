import type { components } from '#types';

export enum AnswerVariant {
  no = 'no',
  yes = 'yes',
  maybe = 'maybe',
}

export type QuestionType = components['schemas']['Question'];

// TODO: тут бы сделать правильное разделение типов
export type QuestionWithAnswersType = components['schemas']['Question'];

export type QuestionPackType = components['schemas']['QuestionPack'];
