<script setup lang="ts">
type MainMenuItem = {
  label?: string;
  divider?: boolean;
  to?: string;
  click?: (event: MouseEvent) => void;
  icon?: string;
  testId?: string;
};

const visible = defineModel<boolean>('visible');

const store = useMainStore();
const { t } = useI18n();

const MOBILE_MENU: MainMenuItem[] = [
  { label: t('navigation.home'), to: store.isPWA ? '/dashboard' : '/' },
  { divider: true },
  { label: t('navigation.about_sex_tasks'), to: '/about-fants' },
  {
    label: t('navigation.play_sex_tasks'),
    to: '/game/settings',
    testId: 'menu-game-link',
  },
  {
    label: t('navigation.custom_tasks'),
    to: '/tasks/custom',
    testId: 'menu-custom-tasks-link',
  },
  { divider: true },
  { label: t('navigation.about_interests_game'), to: '/about-interests' },
  { label: t('navigation.play_in_the_interests'), to: '/interests' },
  { label: t('navigation.show_matches'), to: '/interests/results/compare' },
  { label: t('navigation.select_questions'), to: '/interests/packs' },
  { label: t('navigation.edit_answers'), to: '/interests/change' },
  { divider: true },
  { label: t('navigation.articles'), to: '/articles' },
];
</script>

<template>
  <BaseSidePanel v-model:visible="visible">
    <template v-for="(item, index) in MOBILE_MENU" :key="index">
      <Divider v-if="item.divider" />
      <Button
        v-else
        as="nuxt-link"
        :to="item.to"
        :label="item.label"
        plain
        text
        size="large"
        class="text-center w-full"
        :data-test-id="item.testId"
      />
    </template>
  </BaseSidePanel>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>
