<script setup lang="ts">
import type { MenuItem } from '#types';

type MainMenuItem = {
  label?: string;
  divider?: boolean;
  to?: string;
  click?: (event: MouseEvent) => void;
  icon?: string;
};

const store = useMainStore();
const { t, locale } = useI18n();

const isSideMenuVisible = ref(false);
const menu = ref();
const DESKTOP_MENU: MainMenuItem[] = [
  { label: t('navigation.home'), to: store.isPWA ? '/dashboard' : '/' },
  { label: t('navigation.about_sex_tasks'), to: '/about-fants' },
  { label: t('navigation.play_sex_tasks'), to: '/game/settings' },
  {
    label: t('navigation.get_to_know_partner'),
    icon: 'pi pi-angle-down',
    click: (event: MouseEvent) => menu.value?.toggle(event),
  },
  { label: t('navigation.articles'), to: '/articles' },
];
const INTERESTS_MENU: MenuItem[] = [
  { label: t('navigation.about_interests_game'), to: '/about-interests' },
  { label: t('navigation.play_in_the_interests'), to: '/interests' },
  { label: t('navigation.show_matches'), to: '/interests/results/compare' },
  { label: t('navigation.select_questions'), to: '/interests/packs' },
  { label: t('navigation.edit_answers'), to: '/interests/change' },
];

const badgeCount = computed(
  () =>
    +!store.user.pairedUser +
    +!store.user.isEmailVerified +
    +!store.user.isTwoFactorAuthenticationEnabled,
);

const subscriptionExpiredAtString = computed(() =>
  t('until', {
    date: store.subscriptionExpiredAt?.toLocaleDateString(locale.value),
  }),
);

// const onSupport = () => {
//   MetricAPI.create({
//     body: {
//       type: 'support_button_clicked',
//       data: { page: route.path },
//     },
//   });
// };
</script>

<template>
  <header
    class="h-header shadow-4 flex items-center fixed px-2 pr-3 sm:p-section top-0 left-0 right-0 z-10 bg-surface-900"
  >
    <div class="items-center hidden lg:flex gap-1 xxl:gap-2">
      <Button
        v-for="item in DESKTOP_MENU"
        :key="item.label"
        :as="item.to ? 'nuxt-link' : undefined"
        :to="item.to"
        @click="item.click"
        plain
        text
        :label="item.label"
        :icon="item.icon"
        icon-pos="right"
        class="!px-2 xl:!px-3"
      />
      <Menu ref="menu" :model="INTERESTS_MENU" :popup="true">
        <template #item="{ item, props }">
          <router-link
            v-slot="{ href, navigate }"
            :to="item.to as string"
            custom
          >
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
              {{ item.label }}
            </a>
          </router-link>
        </template>
      </Menu>
    </div>
    <Button
      icon="pi pi-bars"
      text
      plain
      class="lg:!hidden"
      @click="isSideMenuVisible = !isSideMenuVisible"
      :aria-label="t('open')"
      data-test-id="toggle-mobile-menu"
    />
    <ClientOnly>
      <LazyLayoutMobileMenu v-model:visible="isSideMenuVisible" />
    </ClientOnly>

    <!-- <Button -->
    <!--   to="https://boosty.to/sex-play" -->
    <!--   external -->
    <!--   target="_blank" -->
    <!--   @click="onSupport" -->
    <!--   :label="t('support')" -->
    <!--   severity="warn" -->
    <!--   outlined -->
    <!--   rounded -->
    <!--   badge="$" -->
    <!--   class="ml-1 lg:ml-auto lg:mr-2" -->
    <!-- /> -->

    <div class="ml-auto flex items-center gap-2">
      <ClientOnly v-if="store.loaded">
        <LazyLayoutPairedGameStatus />
        <LazyLayoutSexStatusButton />
        <LazyLayoutSexStatusWidget />
      </ClientOnly>

      <Tag
        v-if="store.subscriptionExpiredAt"
        tabindex="0"
        value="pro"
        class="!bg-yellow-400 cursor-pointer"
        v-tooltip.focus.bottom="subscriptionExpiredAtString"
      />
      <Tag
        v-else
        @click="store.isSubscriptionDialogVisible = true"
        tabindex="0"
        value="base"
        severity="danger"
        class="cursor-pointer"
      />
      <Button
        v-if="!store.isAuthorized"
        as="nuxt-link"
        to="/auth/sign-in"
        :label="t('login')"
        text
        class="px-2 sm:px-3"
        data-test-id="head-sign-in-link"
      />
      <Button
        v-if="store.isAuthorized"
        as="nuxt-link"
        to="/profile"
        raised
        rounded
        :aria-label="t('go_to_profile')"
        data-test-id="head-profile-link"
      >
        <span class="hidden xl:block mr-2 xl:mr-0">
          {{ store.user.name }}
        </span>
        <i class="xl:!hidden pi pi-user" />
        <Badge
          v-if="badgeCount"
          :value="badgeCount"
          severity="secondary"
          size="xlarge"
          data-test-id="notification-badge"
        />
      </Button>
    </div>

    <Card class="!hidden" />
  </header>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>
<i18n>
en:
  open: Open menu
  login: Login
  go_to_profile: Go to profile
  support: Support
  until: Do {date}

ru:
  open: Открыть меню
  login: Войти
  go_to_profile: Перейти в профиль
  support: Поддержать
  until: До {date}

de:
  open: Menü öffnen
  login: Login
  go_to_profile: Gehen Sie zum Profil
  support: Unterstützung
  until: Tun {date}

es:
  open: abrir menú
  login: Acceso
  go_to_profile: Ir al perfil
  support: Apoyo
  until: Do {date}

fr:
  open: Ouvrir le menu
  login: Se connecter
  go_to_profile: Aller au profil
  support: Soutien
  until: Faites {date}
</i18n>
