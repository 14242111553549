import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

export const useMainStore = defineStore('main', () => {
  const userStore = useUserStore();

  const notification = ref(false);
  const isSubscriptionDialogVisible = ref(false);

  const sexStatusWidgetDialogVisible = ref(false);
  const sexStatusIdeaDialogVisible = ref(false);

  const isPasskeyEnabled = ref(false);

  // TODO: надо избавиться
  const isAuthorized = computed(() => userStore.loaded);

  const saveWantSex = (message?: string) => {
    if (!userStore.user.wantSex) userStore.user.wantSexMessage = null;
    else if (message) userStore.user.wantSexMessage = message;

    return ProfileAPI.changeWantSex({
      body: { value: userStore.user.wantSex, message },
    });
  };

  const isPWA = computed(() =>
    import.meta.server
      ? false
      : navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches,
  );

  onMounted(() => {
    // TODO: пакет бы вынести из main сторы чтобы не увеличивать вес
    isPasskeyEnabled.value = browserSupportsWebAuthn();
  });

  return {
    // TODO: подчистить бы это
    ...storeToRefs(userStore),
    setUser: userStore.setUser,
    logout: userStore.logout,
    loadUser: userStore.loadUser,

    isAuthorized,
    notification,
    sexStatusWidgetDialogVisible,
    sexStatusIdeaDialogVisible,
    isPWA,
    isSubscriptionDialogVisible,
    isPasskeyEnabled,

    saveWantSex,
  };
});
