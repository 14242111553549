// TODO: грузится всегда просто так - надо оптимизировать
export const useEmailVerificationStore = defineStore(
  'email-verification',
  () => {
    const { t } = useNuxtApp().$i18n;
    const toast = useCustomToast();

    const isVerificationEmailRequest = ref(false);
    const isVerificationEmailSent = ref(false);

    const sendVerificationEmail = () => {
      isVerificationEmailRequest.value = true;

      ProfileAPI.createVerify()
        .then(() => {
          if (!isVerificationEmailSent.value) {
            isVerificationEmailSent.value = true;
          } else {
            toast.add({
              severity: 'success',
              summary: t('notifications.verification_email_sent'),
              life: 3000,
            });
          }
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: t('notifications.verification_email_failed'),
            life: 3000,
          });
        })
        .finally(() => {
          isVerificationEmailRequest.value = false;
        });
    };

    return {
      isVerificationEmailRequest,
      isVerificationEmailSent,

      sendVerificationEmail,
    };
  },
);
