<script lang="ts" setup>
const { t } = useI18n();

useSchemaOrg([
  defineOrganization({
    name: 'Sex Play',
    logo: '/android-chrome-512x512.png',
  }),
  defineWebSite({
    name: 'Sex Play',
    datePublished: new Date(2022, 4, 27, 20).toISOString(),
  }),
  ...Array.from({ length: 3 }, (_, i) =>
    defineComment({
      text: t(`commentaries.${i}.text`),
      datePublished: new Date(2022, 5, 24, 20).toISOString(),
      author: {
        name: t(`commentaries.${i}.name`),
        image: t(`commentaries.${i}.alt`),
      },
    }),
  ),
]);
</script>

<template>
  <slot />
</template>

<i18n src="~/locales/commentaries.yaml"></i18n>
