export const QuestionAPI = {
  random: openapiStrictFetch({ url: 'v2/questions/game' }),
  answers: openapiStrictFetch({ url: 'v2/questions/answers' }),
  compared: openapiStrictFetch({ url: 'v2/questions/answers/compared' }),
  createAnswer: openapiStrictFetch({
    url: 'v2/questions/answers',
    method: HTTPMethod.post,
  }),
  packs: openapiStrictFetch({ url: 'v2/questions/packs' }),
  changeSelectedUserPack: openapiStrictFetch({
    url: 'v2/questions/user-packs',
    method: HTTPMethod.post,
  }),
  archiveAnswer: openapiStrictFetch({
    url: 'v2/questions/{questionId}/archive',
    method: HTTPMethod.post,
  }),
  restoreAnswer: openapiStrictFetch({
    url: 'v2/questions/{questionId}/restore',
    method: HTTPMethod.post,
  }),
};
