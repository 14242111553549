<script lang="ts" setup>
import { PairedGameStatus } from '~/stores/info';

const infoStore = useInfoStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const visible = ref(false);
const feedbackVisible = ref(false);

const discardPairedGame = () => {
  visible.value = false;
  infoStore.discardPairedGame();
};

const statusType = computed(() => {
  if (infoStore.pairedGame?.status === PairedGameStatus.invited) return 'warn';
  return 'success';
});
const statusText = computed(() => {
  const pairedGame = infoStore.pairedGame;
  if (!pairedGame) return '';

  if (pairedGame.status === PairedGameStatus.invited)
    return pairedGame.leaded
      ? t('status.leaded_invited')
      : t('status.partner_invited');
  return pairedGame.leaded
    ? t('status.leaded_accepted')
    : t('status.partner_accepted');
});

const returnPairedGame = () => {
  router.push({
    path: '/game',
    query: { mode: 'paired' },
  });
  visible.value = false;
};

watch(
  () => infoStore.pairedGame,
  (v, prev) => {
    if (prev && !v) visible.value = false;
  },
);
</script>

<template>
  <Transition name="fade">
    <Button
      v-if="infoStore.pairedGame"
      @click="visible = !visible"
      rounded
      size="large"
      :severity="statusType"
      icon="pi pi-users"
    />
  </Transition>

  <Dialog
    v-model:visible="visible"
    style="width: 420px"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
  >
    <template #header>{{ t('title') }}</template>

    {{ statusText }}
    <Button
      @click="
        visible = false;
        feedbackVisible = true;
      "
      text
      size="small"
      class="mt-4 ml-auto block"
    >
      {{ t('feedback') }}
    </Button>

    <template #footer>
      <Button
        @click="discardPairedGame"
        :label="t('complete')"
        icon="pi pi-ban"
        text
      />
      <Button
        v-if="
          (route.path !== '/game' || route.query.mode !== 'paired') &&
          infoStore.pairedGame?.status === PairedGameStatus.accepted
        "
        @click="returnPairedGame"
        :label="t('back')"
        rounded
        class="px-3"
      />
    </template>
  </Dialog>

  <DialogFeedback v-model:visible="feedbackVisible" type="paired-game" />
</template>

<i18n>
en:
  title: Co-op status
  feedback: Leave feedback
  complete: Complete
  back: Back
  status:
    leaded_invited: An invitation to play together has been sent to your partner
    partner_invited: You are invited to play together with your partner
    leaded_accepted: Invitation to play together accepted by partner
    partner_accepted: You accepted an invitation to a doubles game

ru:
  title: Статус совместной игры
  feedback: Оставить отзыв
  complete: Завершить
  back: Вернуться
  status:
    leaded_invited: Приглашение на совместную игру отправлено партнеру
    partner_invited: Вы приглашены на совместную игру с вашим партнером
    leaded_accepted: Приглашение на совместную игру принято партнером
    partner_accepted: Вы приняли приглашение на парную игру

de:
  title: Genossenschaftsstatus
  feedback: Hinterlasse Kommentar
  complete: Vollständig
  back: Zurückkehren
  status:
    leaded_invited: Eine Einladung zum gemeinsamen Spielen wurde an Ihren Partner gesendet
    partner_invited: Sie sind herzlich eingeladen, gemeinsam mit Ihrem Partner zu spielen
    leaded_accepted: Einladung zum gemeinsamen Spielen wird vom Partner angenommen
    partner_accepted: Sie haben eine Einladung zu einem Doppelspiel angenommen

es:
  title: Estado cooperativo
  feedback: Deja un comentario
  complete: Completo
  back: Devolver
  status:
    leaded_invited: Se ha enviado una invitación para jugar juntos a tu pareja.
    partner_invited: Estás invitado a jugar junto con tu pareja.
    leaded_accepted: Invitación para jugar juntos aceptada por el compañero
    partner_accepted: Aceptaste una invitación a un juego de dobles.

fr:
  title: Statut coopératif
  feedback: Laisser les commentaires
  complete: Complet
  back: Retour
  status:
    leaded_invited: Une invitation à jouer ensemble a été envoyée à votre partenaire
    partner_invited: Vous êtes invité à jouer avec votre partenaire
    leaded_accepted: Invitation à jouer ensemble acceptée par le partenaire
    partner_accepted: Vous avez accepté une invitation à un match de double
</i18n>
