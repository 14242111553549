
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93Fxxr7WY7DfMeta } from "/app/pages/(app)/index/articles/[slug].vue?macro=true";
import { default as index0hbcyyaJFSMeta } from "/app/pages/(app)/index/articles/index.vue?macro=true";
import { default as articlesXuniOHR0BrMeta } from "/app/pages/(app)/index/articles.vue?macro=true";
import { default as auth_45without_452faYkhDkP2CZnMeta } from "/app/pages/(app)/index/auth-without-2fa.vue?macro=true";
import { default as indexZRzTiXDBPsMeta } from "/app/pages/(app)/index/auth/index.vue?macro=true";
import { default as recoverykXDB4oABTHMeta } from "/app/pages/(app)/index/auth/recovery.vue?macro=true";
import { default as sign_45inEw8DuHdlt5Meta } from "/app/pages/(app)/index/auth/sign-in.vue?macro=true";
import { default as sign_45upN3nk3DH1fyMeta } from "/app/pages/(app)/index/auth/sign-up.vue?macro=true";
import { default as authX4e769VxKWMeta } from "/app/pages/(app)/index/auth.vue?macro=true";
import { default as dashboardBE0ADfWttZMeta } from "/app/pages/(app)/index/dashboard.vue?macro=true";
import { default as email_45verificationCz5qobVrK5Meta } from "/app/pages/(app)/index/email-verification.vue?macro=true";
import { default as indexuyUFhhgVnVMeta } from "/app/pages/(app)/index/game/index.vue?macro=true";
import { default as settingspBYiJd73orMeta } from "/app/pages/(app)/index/game/settings.vue?macro=true";
import { default as changexSwIdFceWEMeta } from "/app/pages/(app)/index/interests/change.vue?macro=true";
import { default as index6Myx9ojvPoMeta } from "/app/pages/(app)/index/interests/index.vue?macro=true";
import { default as packscX6buxKH2zMeta } from "/app/pages/(app)/index/interests/packs.vue?macro=true";
import { default as archivel0DS6JiigUMeta } from "/app/pages/(app)/index/interests/results/archive.vue?macro=true";
import { default as compareLtMcudEe10Meta } from "/app/pages/(app)/index/interests/results/compare.vue?macro=true";
import { default as resultsk8mEDggGT7Meta } from "/app/pages/(app)/index/interests/results.vue?macro=true";
import { default as interests1plcsO1dwDMeta } from "/app/pages/(app)/index/interests.vue?macro=true";
import { default as invite8SX8hcQ7QFMeta } from "/app/pages/(app)/index/invite.vue?macro=true";
import { default as profileY7PTO73YGOMeta } from "/app/pages/(app)/index/profile.vue?macro=true";
import { default as _91id_93TC96MeATmYMeta } from "/app/pages/(app)/index/tasks/[id].vue?macro=true";
import { default as _91taskId_93yyzaj1SdzSMeta } from "/app/pages/(app)/index/tasks/custom/[taskId].vue?macro=true";
import { default as createTy3E2Gf8EIMeta } from "/app/pages/(app)/index/tasks/custom/create.vue?macro=true";
import { default as indexwrZcdctJOAMeta } from "/app/pages/(app)/index/tasks/custom/index.vue?macro=true";
import { default as custombVPdkSc8Q8Meta } from "/app/pages/(app)/index/tasks/custom.vue?macro=true";
import { default as telegram_45paymentpOIsqZPYjoMeta } from "/app/pages/(app)/index/telegram-payment.vue?macro=true";
import { default as indexp7TBqRrHf4Meta } from "/app/pages/(misc)/index.vue?macro=true";
import { default as indexCxADpQObNCMeta } from "/app/pages/(app)/index.vue?macro=true";
import { default as about_45fantsNkLQJuuzAJMeta } from "/app/pages/(misc)/about-fants.vue?macro=true";
import { default as about_45interestsLP9ZdVQgJVMeta } from "/app/pages/(misc)/about-interests.vue?macro=true";
export default [
  {
    name: indexCxADpQObNCMeta?.name,
    path: "/",
    component: () => import("/app/pages/(app)/index.vue"),
    children: [
  {
    name: articlesXuniOHR0BrMeta?.name,
    path: "articles",
    component: () => import("/app/pages/(app)/index/articles.vue"),
    children: [
  {
    name: "index-articles-slug",
    path: ":slug()",
    component: () => import("/app/pages/(app)/index/articles/[slug].vue")
  },
  {
    name: "index-articles",
    path: "",
    component: () => import("/app/pages/(app)/index/articles/index.vue")
  }
]
  },
  {
    name: "index-auth-without-2fa",
    path: "auth-without-2fa",
    component: () => import("/app/pages/(app)/index/auth-without-2fa.vue")
  },
  {
    name: authX4e769VxKWMeta?.name,
    path: "auth",
    component: () => import("/app/pages/(app)/index/auth.vue"),
    children: [
  {
    name: "index-auth",
    path: "",
    component: () => import("/app/pages/(app)/index/auth/index.vue")
  },
  {
    name: "index-auth-recovery",
    path: "recovery",
    component: () => import("/app/pages/(app)/index/auth/recovery.vue")
  },
  {
    name: "index-auth-sign-in",
    path: "sign-in",
    component: () => import("/app/pages/(app)/index/auth/sign-in.vue")
  },
  {
    name: "index-auth-sign-up",
    path: "sign-up",
    component: () => import("/app/pages/(app)/index/auth/sign-up.vue")
  }
]
  },
  {
    name: "index-dashboard",
    path: "dashboard",
    meta: dashboardBE0ADfWttZMeta || {},
    component: () => import("/app/pages/(app)/index/dashboard.vue")
  },
  {
    name: "index-email-verification",
    path: "email-verification",
    component: () => import("/app/pages/(app)/index/email-verification.vue")
  },
  {
    name: "index-game",
    path: "game",
    component: () => import("/app/pages/(app)/index/game/index.vue")
  },
  {
    name: "index-game-settings",
    path: "game/settings",
    component: () => import("/app/pages/(app)/index/game/settings.vue")
  },
  {
    name: interests1plcsO1dwDMeta?.name,
    path: "interests",
    meta: interests1plcsO1dwDMeta || {},
    component: () => import("/app/pages/(app)/index/interests.vue"),
    children: [
  {
    name: "index-interests-change",
    path: "change",
    component: () => import("/app/pages/(app)/index/interests/change.vue")
  },
  {
    name: "index-interests",
    path: "",
    component: () => import("/app/pages/(app)/index/interests/index.vue")
  },
  {
    name: "index-interests-packs",
    path: "packs",
    component: () => import("/app/pages/(app)/index/interests/packs.vue")
  },
  {
    name: "index-interests-results",
    path: "results",
    component: () => import("/app/pages/(app)/index/interests/results.vue"),
    children: [
  {
    name: "index-interests-results-archive",
    path: "archive",
    component: () => import("/app/pages/(app)/index/interests/results/archive.vue")
  },
  {
    name: "index-interests-results-compare",
    path: "compare",
    component: () => import("/app/pages/(app)/index/interests/results/compare.vue")
  }
]
  }
]
  },
  {
    name: "index-invite",
    path: "invite",
    component: () => import("/app/pages/(app)/index/invite.vue")
  },
  {
    name: "index-profile",
    path: "profile",
    meta: profileY7PTO73YGOMeta || {},
    component: () => import("/app/pages/(app)/index/profile.vue")
  },
  {
    name: "index-tasks-id",
    path: "tasks/:id()",
    component: () => import("/app/pages/(app)/index/tasks/[id].vue")
  },
  {
    name: custombVPdkSc8Q8Meta?.name,
    path: "tasks/custom",
    meta: custombVPdkSc8Q8Meta || {},
    component: () => import("/app/pages/(app)/index/tasks/custom.vue"),
    children: [
  {
    name: "index-tasks-custom-taskId",
    path: ":taskId()",
    component: () => import("/app/pages/(app)/index/tasks/custom/[taskId].vue")
  },
  {
    name: "index-tasks-custom-create",
    path: "create",
    component: () => import("/app/pages/(app)/index/tasks/custom/create.vue")
  },
  {
    name: "index-tasks-custom",
    path: "",
    component: () => import("/app/pages/(app)/index/tasks/custom/index.vue")
  }
]
  },
  {
    name: "index-telegram-payment",
    path: "telegram-payment",
    component: () => import("/app/pages/(app)/index/telegram-payment.vue")
  },
  {
    name: "index",
    path: "",
    meta: indexp7TBqRrHf4Meta || {},
    component: () => import("/app/pages/(misc)/index.vue")
  }
]
  },
  {
    name: "about-fants",
    path: "/about-fants",
    meta: about_45fantsNkLQJuuzAJMeta || {},
    component: () => import("/app/pages/(misc)/about-fants.vue")
  },
  {
    name: "about-interests",
    path: "/about-interests",
    meta: about_45interestsLP9ZdVQgJVMeta || {},
    component: () => import("/app/pages/(misc)/about-interests.vue")
  }
]