<script lang="ts" setup>
const config = useRuntimeConfig();

config.public.metricScripts.forEach((el) => {
  useScript({
    src: el,
    tagPosition: 'bodyClose',
    async: true,
  });
});
</script>

<template />
