import { onBeforeRouteLeave } from 'vue-router';

export default async () => {
  const store = useMainStore();
  const router = useRouter();
  const route = useRoute();
  const authorized = useCookie('authorized');

  const isLeaved = ref(false);

  const path = computed(() => ({
    path: '/auth/sign-in',
    query: { authRedirect: route.fullPath },
  }));

  if (!authorized.value && !isLeaved.value)
    await navigateTo(path.value, { redirectCode: 302 });

  watch(
    () => store.isLogout,
    () => {
      if (store.isLogout && !isLeaved.value) router.push(path.value);
    },
  );

  onBeforeRouteLeave(() => {
    isLeaved.value = true;
  });
};
