<script lang="ts" setup>
import { vAutoAnimate } from '@formkit/auto-animate/vue';

withDefaults(
  defineProps<{
    duration?: number;
  }>(),
  { duration: 600 },
);

const isCypress = useCookie<boolean>('cypress');

const vDirective = isCypress.value ? {} : vAutoAnimate;
</script>

<template>
  <div v-directive="{ duration }" class="baseToggleTransition">
    <slot />
  </div>
</template>

<style lang="scss">
.baseToggleTransition {
  transform: initial !important;

  & > * {
    top: 0 !important;
    translate: initial !important;
  }
}
</style>
