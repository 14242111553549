export default defineNuxtPlugin((nuxtApp) => {
  const csrf = useState<string>('csrf_token');
  const serverSideRequestKey = nuxtApp.ssrContext?.runtimeConfig
    .serverSideRequestKey as string;
  const initialOptions: StrictFetchOptions = {
    baseURL: `${nuxtApp.ssrContext?.runtimeConfig.apiServerHost || ''}/api/`,
    headers: serverSideRequestKey
      ? { 'X-Server-Side-Request-Key': serverSideRequestKey }
      : {},
    proxyServerCookies: true,
    onRequest: (context) => {
      for (const key in context.options.params) {
        if (
          Array.isArray(context.options.params[key]) &&
          context.options.params[key].length
        ) {
          context.options.params[key] = context.options.params[key].join(',');
        }
      }

      const xForwardedFor =
        nuxtApp.ssrContext?.event.headers.get('X-Forwarded-For');
      if (xForwardedFor)
        context.options.headers.set('X-Forwarded-For', xForwardedFor);

      if (csrf.value) context.options.headers.set('x-csrf-token', csrf.value);
    },
    onResponse: (context) => {
      if (nuxtApp.ssrContext) {
        const cookies = context.response.headers.getSetCookie();
        for (const cookie of cookies) {
          nuxtApp.ssrContext.event.node.res.setHeader('Set-Cookie', cookie);
        }
      }

      const csrfToken = context.response.headers.get('x-csrf-token');
      if (csrfToken) csrf.value = csrfToken;
    },
  };

  const refreshState = {
    isRequest: false,
    subscribers: [] as {
      resolve: (v: unknown) => void;
      reject: (v: unknown) => void;
    }[],
  };

  StrictFetch.init({
    ...initialOptions,
    catch: (error) => {
      if (error.context?.response?.status === 401 && import.meta.client) {
        if (!refreshState.isRequest) {
          refreshState.isRequest = true;
          return $fetch('auth/refresh', {
            ...initialOptions,
            method: HTTPMethod.post,
          })
            .then(() => {
              refreshState.subscribers.forEach((el) => el.resolve(true));
              refreshState.subscribers = [];
              return $fetch(
                error.context?.request || '',
                error.context?.options as any,
              );
            })
            .catch(() => {
              refreshState.subscribers.forEach((el) => el.reject(error));
              refreshState.subscribers = [];
              return Promise.reject(error);
            })
            .finally(() => {
              refreshState.isRequest = false;
            });
        } else {
          return new Promise((resolve, reject) => {
            refreshState.subscribers.push({
              resolve,
              reject,
            });
          }).then(
            () =>
              $fetch.raw(
                error.context?.request || '',
                error.context?.options as any,
              ) as any,
          );
        }
      }
      return Promise.reject(error);
    },
  });

  return { provide: { requestRefreshState: refreshState } };
});
