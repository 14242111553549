import type { components } from '#types';

export enum Sex {
  male = 'm',
  female = 'f',
}

export type UserType = components['schemas']['User'];

export type PasskeyType = components['schemas']['Passkey'];

export type NotificationTokenType = {
  id: number;
  token: string;
  value: boolean;
};
