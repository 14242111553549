<script lang="ts" setup>
const toast = useCustomToast();
const { t } = useI18n();

const props = defineProps<{
  type: string;
  additional?: string;
}>();
const visible = defineModel<boolean>('visible', { default: false });

const message = ref('');
const isLoading = ref(false);

const sendFeedback = () => {
  if (!message.value) return;
  isLoading.value = true;

  FeedbackAPI.send({
    body: { content: `${message.value}\n\n${props.additional}` },
    query: { type: props.type },
  })
    .then(() => {
      toast.add({
        severity: 'success',
        summary: t('message.success'),
        life: 5000,
      });
      message.value = '';
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('message.error'),
        life: 5000,
      });
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>

<template>
  <Dialog
    v-model:visible="visible"
    style="width: 420px"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
  >
    <template #header>{{ t('title') }}</template>

    <span class="leading-4">{{ t('description') }}</span>
    <Textarea
      v-model="message"
      :placeholder="t('placeholder')"
      class="w-full mt-3 max-h-12rem"
      rows="3"
      auto-resize
    />

    <template #footer>
      <Button
        @click="visible = false"
        :label="t('close')"
        icon="pi pi-times"
        severity="secondary"
        text
      />
      <Button
        @click="sendFeedback"
        :label="t('send')"
        icon="pi pi-send"
        :disabled="!message"
        :loading="isLoading"
      />
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Feedback
  description: >
    Tell us what you would like to improve, what problems you have encountered or what do you think about a new
    opportunity so that we can become even better? :)
  placeholder: Your feedback
  close: Close
  send: Send
  message:
    success: Thank you for your opinion, it is extremely important to us!
    error: An error occurred while sending feedback

ru:
  title: Обратная связь
  description: >
    Расскажите, что бы вы хотели улучшить, с какими проблемами столкнулись или что вы думаете о новой возможности, чтобы
    мы могли становиться еще лучше :)
  placeholder: Ваш отзыв
  close: Закрыть
  send: Отправить
  message:
    success: Спасибо за ваше мнение, оно крайне важно для нас!
    error: Во время отправки обратной связи произошла ошибка

de:
  title: Rückmeldung
  description: Sagen Sie uns, was Sie verbessern möchten, auf welche Probleme Sie gestoßen sind oder was Sie von einer
    neuen Funktion halten, damit wir noch besser werden können :)
  placeholder: Ihre Bewertung
  close: Schließen
  send: Schicken
  message:
    success: Vielen Dank für Ihre Meinung, sie ist uns sehr wichtig!
    error: Beim Senden des Feedbacks ist ein Fehler aufgetreten

es:
  title: Comentario
  description: Cuéntanos qué te gustaría mejorar, qué problemas encontraste o qué piensas sobre una nueva función para que
    podamos mejorar aún más :)
  placeholder: Tu reseña
  close: Cerca
  send: Enviar
  message:
    success: ¡Gracias por tu opinión, es extremadamente importante para nosotros!
    error: Se produjo un error al enviar comentarios.

fr:
  title: Retour
  description: Dites-nous ce que vous aimeriez améliorer, quels problèmes vous avez rencontrés ou ce que vous pensez d'une
    nouvelle fonctionnalité afin que nous puissions devenir encore meilleurs :)
  placeholder: Votre avis
  close: Fermer
  send: Envoyer
  message:
    success: Merci pour votre avis, c'est extrêmement important pour nous !
    error: Une erreur s'est produite lors de l'envoi des commentaires
</i18n>
