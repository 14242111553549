import payload_plugin_oNncJsJdcG from "/_base/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_l94A7Dbezp from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xqwxWlIfUA from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ducmoyqp7S from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zm6vVPR2OE from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_rfi4du4lqcedbkt4sn2g53i3ta/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_fnJjFMtmwt from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y9vWf9yowJ from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4uNjaph83w from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6QGODhNxxc from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_NMDzBqorCd from "/_base/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_OGGDE5cuft from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__twh3yga7rcsvqznkz5kzmzojw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Aya2dn1x0X from "/_base/node_modules/.pnpm/nuxt-strict-fetch@0.7.2_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-strict-fetch/dist/runtime/plugin.js";
import switch_locale_path_ssr_oc9TfF57VK from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_nebyfme3wwny6njuwiow22hq24/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_qSc4vRE4ih from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_nebyfme3wwny6njuwiow22hq24/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_ey4nEyvKxa from "/_base/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.26.5_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_BXlJr8Oagg from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2__czzi4oxuzjttgmmefrkbzj3seu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_request_6u004rKQEG from "/_base/plugins/0.request.ts";
import nuxt_link_Z6CHffTE6l from "/_base/plugins/nuxt-link.ts";
import user_1TSimXl2Ec from "/_base/plugins/user.ts";
import autoAnimate_4HQGapz9xs from "/app/plugins/autoAnimate.ts";
import showOnVisible_client_48SaagSnTk from "/app/plugins/showOnVisible.client.ts";
export default [
  payload_plugin_oNncJsJdcG,
  revive_payload_client_l94A7Dbezp,
  unhead_xqwxWlIfUA,
  router_ducmoyqp7S,
  _0_siteConfig_zm6vVPR2OE,
  payload_client_fnJjFMtmwt,
  navigation_repaint_client_Y9vWf9yowJ,
  check_outdated_build_client_4uNjaph83w,
  chunk_reload_client_6QGODhNxxc,
  plugin_vue3_NMDzBqorCd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OGGDE5cuft,
  plugin_Aya2dn1x0X,
  switch_locale_path_ssr_oc9TfF57VK,
  i18n_qSc4vRE4ih,
  primevue_plugin_egKpok8Auk,
  plugin_client_ey4nEyvKxa,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_BXlJr8Oagg,
  _0_request_6u004rKQEG,
  nuxt_link_Z6CHffTE6l,
  user_1TSimXl2Ec,
  autoAnimate_4HQGapz9xs,
  showOnVisible_client_48SaagSnTk
]