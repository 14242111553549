import io, { Socket } from 'socket.io-client';

import type { TaskType } from '~/types/tasks';

export enum PairedGameStatus {
  invited,
  accepted,
}

enum WSEvent {
  wantSex = 'want_sex',
  subscriptionUpdated = 'subscription_updated',

  currentPairedGame = 'current_paired_game',
  invitePairedGame = 'invite_paired_game',
  acceptPairedGame = 'accept_paired_game',
  discardPairedGame = 'discard_paired_game',
  sendPartnerTask = 'send_partner_task',
  completePartnerTask = 'complete_partner_task',
}

interface InfoSocketType
  extends Socket<{
    [WSEvent.wantSex]: (event: { value: boolean; message?: string }) => void;
    [WSEvent.subscriptionUpdated]: (event: {}) => void;
    [WSEvent.currentPairedGame]: (event: {
      status: PairedGameStatus;
      leaded: boolean;
      task: TaskType | null;
    }) => void;
    [WSEvent.invitePairedGame]: () => void;
    [WSEvent.acceptPairedGame]: () => void;
    [WSEvent.discardPairedGame]: () => void;
    [WSEvent.sendPartnerTask]: (task: TaskType | null) => void;
    [WSEvent.completePartnerTask]: () => void;
  }> {}

const RECONNECT_ON_DEVELOPMENT = false;

const EMPTY_MESSAGE_KEY = 'want_sex_empty_idea_notification';

const getShowedWantSexMessageExpired = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

const digest = async (message: string) =>
  Array.from(
    new Uint8Array(
      await crypto.subtle.digest('SHA-1', new TextEncoder().encode(message)),
    ),
    (byte) => byte.toString(16).padStart(2, '0'),
  ).join('');

export const useInfoStore = defineStore('info', () => {
  const config = useRuntimeConfig();
  const t = useNuxtApp().$i18n.t;
  const toast = useCustomToast();
  const mainStore = useMainStore();

  const initialized = ref(false);
  const socket = ref({} as InfoSocketType);

  const pairedGame = ref<{
    status: PairedGameStatus;
    leaded: boolean;
    task?: TaskType | null;
    isCompleted?: boolean;
  }>();

  const showedWantSexMessage = useCookie('showed_want_sex_message', {
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    domain: config.public.baseDomain,
    sameSite: 'lax',
    expires: getShowedWantSexMessageExpired(),
  });

  const init = () => {
    if (initialized.value || import.meta.server) return;

    socket.value = io(location.origin, {
      reconnection:
        process.env.NODE_ENV === 'production' || RECONNECT_ON_DEVELOPMENT,
    });

    socket.value.on(WSEvent.wantSex, (event) => {
      if (mainStore.user.pairedUser) {
        // TODO: сложная синхронизация message с логикой на обеих сторонах
        mainStore.user.pairedUser.wantSex = event.value as boolean;
        if (!event.value) {
          mainStore.user.pairedUser.wantSexMessage = null;
          showedWantSexMessage.value = null;
        } else if (event.message)
          mainStore.user.pairedUser.wantSexMessage = event.message;
        if (event.value) {
          digest(event.message || EMPTY_MESSAGE_KEY).then((hash) => {
            showedWantSexMessage.value = hash;
          });

          // TODO: все уведомления (т.е. по сути UI) не должны быть в сторах
          toast.add({
            severity: 'info',
            summary: t('notifications.want_sex'),
            detail:
              event.message &&
              t('notifications.want_sex_description', {
                content: event.message,
              }),
          });
          // TODO: подумать лучше об аудио
          // const audio = new Audio('/audio/info-notification.wav');
          // audio.play();
        }
      }
    });

    socket.value.on(WSEvent.subscriptionUpdated, () => {
      mainStore.loadUser().then(() => {
        toast.add({
          severity: 'success',
          summary: t('notifications.subscription_updated_title'),
          detail: t('notifications.subscription_updated_description'),
        });
      });
    });

    socket.value.on(WSEvent.invitePairedGame, () => {
      pairedGame.value = {
        status: PairedGameStatus.invited,
        leaded: false,
      };

      MetricAPI.create({
        body: {
          type: 'paired_game_invited',
          data: { user_type: 'subject' },
        },
      });
    });

    socket.value.on(WSEvent.acceptPairedGame, () => {
      toast.add({
        severity: 'success',
        summary: t('notifications.accept_paired_game_received'),
        life: 5000,
      });
      pairedGame.value = {
        status: PairedGameStatus.accepted,
        leaded: true,
      };

      MetricAPI.create({
        body: {
          type: 'paired_game_accepted',
          data: { user_type: 'leader' },
        },
      });
    });

    socket.value.on(WSEvent.sendPartnerTask, (event) => {
      if (pairedGame.value) pairedGame.value.task = event;
    });

    socket.value.on(WSEvent.completePartnerTask, () => {
      if (pairedGame.value) {
        pairedGame.value.isCompleted = true;

        nextTick(() => {
          if (pairedGame.value) pairedGame.value.isCompleted = false;
        });
      }
    });

    socket.value.on(WSEvent.currentPairedGame, (event) => {
      pairedGame.value = event;
    });

    socket.value.on(WSEvent.discardPairedGame, () => {
      pairedGame.value = undefined;
      toast.add({
        severity: 'info',
        summary: t('notifications.discard_paired_game_received'),
        life: 5000,
      });
    });

    initialized.value = true;
  };

  const inviteToPairedGame = () => {
    socket.value.emit(WSEvent.invitePairedGame);
    toast.add({
      severity: 'success',
      summary: t('notifications.invite_paired_game_sent'),
      life: 5000,
    });

    pairedGame.value = {
      status: PairedGameStatus.invited,
      leaded: true,
    };

    MetricAPI.create({
      body: {
        type: 'paired_game_invited',
        data: { user_type: 'leaded' },
      },
    });
  };

  const acceptPairedGame = () => {
    socket.value.emit(WSEvent.acceptPairedGame);
    toast.add({
      severity: 'success',
      summary: t('notifications.accept_paired_game_sent'),
      life: 5000,
    });

    pairedGame.value = {
      status: PairedGameStatus.accepted,
      leaded: false,
    };

    MetricAPI.create({
      body: {
        type: 'paired_game_accepted',
        data: { user_type: 'subject' },
      },
    });
  };

  const discardPairedGame = () => {
    socket.value.emit(WSEvent.discardPairedGame);
    toast.add({
      severity: 'info',
      summary: t('notifications.discard_paired_game_sent'),
      life: 5000,
    });

    MetricAPI.create({
      body: {
        type: 'paired_game_discarded',
        data: { user_type: pairedGame.value?.leaded ? 'leader' : 'subject' },
      },
    });

    pairedGame.value = undefined;
  };

  const sendPartnerTask = (task: TaskType | null) => {
    socket.value.emit(WSEvent.sendPartnerTask, task);
  };

  const completePartnerTask = () => {
    socket.value.emit(WSEvent.completePartnerTask);
  };

  const close = () => {
    if (socket.value instanceof Socket) socket.value.disconnect();
    initialized.value = false;
  };

  watch(
    () => mainStore.user,
    async (u) => {
      if (!import.meta.client || !u?.pairedUser) return;

      if (!u.pairedUser.wantSex) {
        showedWantSexMessage.value = null;
        return;
      }

      let hash =
        u.pairedUser.wantSexMessage &&
        (await digest(u.pairedUser.wantSexMessage));
      if (hash && hash !== showedWantSexMessage.value) {
        showedWantSexMessage.value = hash;
        toast.add({
          severity: 'info',
          summary: t('notifications.want_sex'),
          detail: t('notifications.want_sex_description', {
            content: u.pairedUser.wantSexMessage,
          }),
        });
      } else if (!u.pairedUser.wantSexMessage) {
        hash = await digest(EMPTY_MESSAGE_KEY);
        if (hash !== showedWantSexMessage.value) {
          showedWantSexMessage.value = hash;
          toast.add({
            severity: 'info',
            summary: t('notifications.want_sex'),
          });
        }
      }
    },
    { immediate: true },
  );

  return {
    initialized,
    socket,
    pairedGame,

    init,
    close,

    inviteToPairedGame,
    acceptPairedGame,
    discardPairedGame,
    sendPartnerTask,
    completePartnerTask,
  };
});
