<script lang="ts" setup>
import type { QuestionWithAnswersType } from '~/types/questions';

const LIMIT = 20;

const store = useMainStore();
const featureStore = useFeatureStore();
const toast = useCustomToast();
const confirm = useCustomConfirm();
const { t } = useI18n();

const visibleInstructionDialog = ref(false);
const ideaContent = ref('');
const ideas = ref<QuestionWithAnswersType[]>([]);
const content = ref<HTMLDivElement>();
const isIdeaSending = ref(false);
const isIdeasLoading = ref(true);
const isIdeasFinished = ref(false);

const isNotificationEnabled = computed(
  () => store.notification || store.user.isEmailNotificationsEnabled,
);

const toggleWantSexStatus = async (message?: string) => {
  store.user.wantSex = message ? true : !store.user.wantSex;

  return store
    .saveWantSex(message)
    .then(() => {
      toast.add({
        severity: 'success',
        summary: t('want_sex_updating_success'),
        life: 3000,
      });
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('want_sex_updating_error'),
        life: 3000,
      });
    });
};

const loadIdeas = async (offset = 0) => {
  if (isIdeasLoading.value || isIdeasFinished.value) return;
  isIdeasLoading.value = true;

  return QuestionAPI.compared({
    query: {
      offset,
      limit: LIMIT,
    },
  }).then((data) => {
    if (data.length < LIMIT) isIdeasFinished.value = true;

    ideas.value = [...ideas.value, ...data];
    isIdeasLoading.value = false;
  });
};

const onScroll = async () => {
  if (!content.value) return;

  if (
    content.value.scrollTop + content.value.clientHeight >
    content.value.scrollHeight - 200
  )
    loadIdeas(ideas.value.length).then(() => {
      if (!isIdeasLoading.value && !isIdeasFinished.value) onScroll();
    });
};

const sendIdea = (idea?: QuestionWithAnswersType) => {
  if (isIdeaSending.value) return;
  isIdeaSending.value = true;

  let message = idea
    ? `${idea.question}${idea.pairedQuestion ? ` / ${idea.pairedQuestion}` : ''}`
    : ideaContent.value;
  message = t(`message.idea_${idea ? 'selected' : 'custom'}`, {
    content: message,
  });

  confirm.require({
    header: t('message.idea_title'),
    message,
    accept: () =>
      toggleWantSexStatus(message)
        .then(() => {
          store.sexStatusIdeaDialogVisible = false;
          ideaContent.value = '';
        })
        .finally(() => {
          isIdeaSending.value = false;
        }),
    acceptLabel: t('message.send'),
    rejectLabel: t('message.cancel'),
  });
};

watch(content, (v) => {
  v?.addEventListener('scroll', onScroll);
});

watch(
  () => store.sexStatusWidgetDialogVisible,
  (v) => {
    if (!v) store.sexStatusIdeaDialogVisible = false;
  },
);

watch(
  () => store.sexStatusIdeaDialogVisible,
  (v) => {
    if (v && !ideas.value.length) {
      isIdeasLoading.value = false;
      loadIdeas();
    }
  },
);

onMounted(() => {
  featureStore.isSexStatusWidgetInitialized = true;
});
</script>

<template>
  <Dialog
    v-model:visible="store.sexStatusWidgetDialogVisible"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
    class="sexStatusWidget__dialog w-120"
    :pt="{
      content: {
        ref: (v: HTMLDivElement) => (content = v),
        class: 'overflow-x-hidden',
      },
    }"
  >
    <template #header>
      <div class="flex gap-2 items-center">
        <span class="p-dialog-title">{{ t('title') }}</span>

        <Button
          @click="visibleInstructionDialog = true"
          icon="pi pi-question-circle"
          text
          rounded
          class="w-1.5rem h-1.5rem"
        />
      </div>
    </template>

    <BaseToggleTransition>
      <div v-if="!store.sexStatusIdeaDialogVisible">
        <div class="text-lg text-center pt-1 pb-2">
          <template v-if="store.user.wantSex">
            <Message
              v-if="store.user.wantSexMessage"
              severity="success"
              variant="outlined"
              class="text-start"
            >
              {{ t('your_status_with_message') }}
              <br />
              <span class="text-sm block text-slate-100">
                {{ store.user.wantSexMessage }}
              </span>
            </Message>
            <p v-else class="mt-0">
              {{ t('you_want_sex.0') }}
              <span style="color: var(--success-color)">{{ t('want') }}</span>
              {{ t('you_want_sex.1') }}
            </p>
          </template>
          <p v-else class="mt-0">
            {{ t('you_not_ready_for_sex.0') }}
            <span style="color: var(--error-color)">{{ t('not_ready') }}</span>
            {{ t('you_not_ready_for_sex.1') }}
          </p>

          <template v-if="store.user.pairedUser?.wantSex">
            <Message
              v-if="store.user.pairedUser.wantSexMessage"
              severity="success"
              variant="outlined"
              class="text-start mt-2"
            >
              {{ t('partner_status_with_message') }}
              <br />
              <span class="text-sm block text-slate-100">
                {{ store.user.pairedUser.wantSexMessage }}
              </span>
            </Message>
            <p v-else>
              {{ t('your_partner_wants_sex.0') }}
              <span style="color: var(--success-color)">
                {{ t('partner_want') }}
              </span>
              {{ t('your_partner_wants_sex.1') }}
            </p>
          </template>
          <p v-else>
            {{ t('your_partner_is_not_ready_for_sex.0') }}
            <span style="color: var(--error-color)">
              {{ t('partner_not_ready') }}
            </span>
            {{ t('your_partner_is_not_ready_for_sex.1') }}
          </p>
        </div>

        <div
          class="flex justify-content-around"
          :class="{ 'pointer-events-none': !store.pro }"
        >
          <div class="flex items-center flex-col">
            <Button text class="sexStatusWidget__toggle mb-2">
              <BaseFireIcon
                :active="store.user.wantSex"
                class="w-6rem"
                @click="toggleWantSexStatus()"
              />
            </Button>
            {{ t('you') }}
          </div>
          <div class="flex items-center flex-col">
            <BaseFireIcon
              :active="store.user.pairedUser?.wantSex"
              class="w-6rem mb-2"
            />
            {{ t('partner') }}
          </div>
        </div>

        <div :class="{ 'opacity-50 pointer-events-none': !store.pro }">
          <span
            data-onboarding="sex-status-widget-toggle"
            class="flex items-center mt-4"
          >
            <span
              class="mr-2 flex-shrink-1 text-base xl:text-lg"
              style="flex: 1"
            >
              {{ t('change_want_sex') }}
            </span>
            <ToggleSwitch
              class="ml-auto"
              :model-value="store.user.wantSex"
              @update:model-value="toggleWantSexStatus()"
            />
          </span>
          <Button
            @click="store.sexStatusIdeaDialogVisible = true"
            :label="t('send_idea')"
            outlined
            :loading="isIdeaSending"
            data-onboarding="sex-status-widget-idea"
            class="w-full mt-3"
          />
        </div>
        <Button
          v-if="!store.pro"
          @click="store.isSubscriptionDialogVisible = true"
          :label="t('unlock')"
          class="w-full mt-3"
        />
      </div>

      <div v-else>
        <Textarea
          v-model="ideaContent"
          :placeholder="t('idea_placeholder')"
          auto-resize
          rows="3"
          data-onboarding="sex-status-widget-input"
          class="w-full mt-3"
        />
        <Button
          @click="sendIdea()"
          :label="t('message.send')"
          :disabled="!ideaContent"
          outlined
          class="w-full mt-3"
        />
        <Divider />

        <BaseToggleTransition data-onboarding="sex-status-widget-list">
          <span class="block mb-5 text-sm">
            {{ t('idea_selection') }}
          </span>
          <div v-if="ideas.length" class="flex flex-col gap-2">
            <BaseCompareCard
              v-for="question in ideas"
              :key="question.id"
              @click="sendIdea(question)"
              :question="question"
              hoverable
            />
          </div>
          <div v-else class="flex flex-col items-center gap-5">
            <span class="text-sm block text-center">
              {{ t('idea_empty') }}
            </span>
            <Button
              as="nuxt-link"
              to="/interests"
              :label="t('idea_add')"
              outlined
              class="w-full"
            />
          </div>
          <div
            v-if="isIdeasLoading"
            data-onboarding="sex-status-widget-loader"
            class="p-8 flex items-center justify-center"
          >
            <i class="pi pi-spin pi-spinner text-2xl" />
          </div>
        </BaseToggleTransition>
      </div>
    </BaseToggleTransition>

    <template #footer>
      <Transition name="fade">
        <Button
          v-if="store.sexStatusIdeaDialogVisible"
          :label="t('back')"
          icon="pi pi-arrow-left"
          @click="store.sexStatusIdeaDialogVisible = false"
          text
        />
      </Transition>
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="store.sexStatusWidgetDialogVisible = false"
        text
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="visibleInstructionDialog"
    :header="t('instruction_title')"
    style="width: 420px"
    dismissable-mask
    :base-z-index="6"
    :draggable="false"
    modal
  >
    <p class="leading-6 mt-0">
      {{ t('instruction_description') }}
    </p>
    <NuxtImg
      src="/images/instructions/want-sex-notification.png"
      format="webp"
      loading="lazy"
      class="w-full h-auto"
      width="370"
      height="196"
      style="aspect-ratio: 370 / 196"
    />
    <p class="leading-6">
      {{ t('instruction_actions_title') }}
    </p>
    <ul class="pl-0">
      <li class="flex gap-3 items-center mb-3">
        <i
          v-if="store.isAuthorized && store.user.pairedUser"
          class="pi pi-check-circle text-xl"
          style="color: #9fdaa8"
        />
        <i v-else class="pi pi-times-circle text-xl" style="color: #f19ea6" />
        <span>
          {{
            store.isAuthorized && store.user.pairedUser
              ? t('instruction_actions.0.success')
              : t('instruction_actions.0.failed')
          }}
        </span>
      </li>
      <li class="flex gap-3 items-center mb-3">
        <i
          v-if="isNotificationEnabled"
          class="pi pi-check-circle text-xl"
          style="color: #9fdaa8"
        />
        <i v-else class="pi pi-times-circle text-xl" style="color: #f19ea6" />
        <span>
          {{
            isNotificationEnabled
              ? t('instruction_actions.1.success')
              : t('instruction_actions.1.failed')
          }}
        </span>
      </li>
    </ul>
    <p class="leading-6 mb-0">
      {{ t('instruction_hint') }}
    </p>

    <template #footer>
      <Button
        as="nuxt-link"
        to="/profile"
        :label="t('instruction_to_profile')"
        @click="
          visibleInstructionDialog = false;
          store.sexStatusWidgetDialogVisible = false;
        "
        text
      />
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="visibleInstructionDialog = false"
        text
      />
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Sex desire status
  want: want
  not_ready: not ready
  you_want_sex:
    - You
    - to have sex
  you_not_ready_for_sex:
    - You are
    - to have sex!
  your_partner_wants_sex:
    - Your partner
    - to have sex
  your_partner_is_not_ready_for_sex:
    - Your partner is
    - for sex
  you: You
  partner: Partner
  change_want_sex: 'Change sex desire status:'
  close: Close
  instruction_title: How it works
  instruction_description: Desire Status is the ability to tell your partner that you want to have sex today. When you
    make your status active, your partner will receive a notification on their device.
  instruction_actions_title: 'Required Actions:'
  instruction_actions:
    - success: Partner added
      failed: You have not added a partner
    - success: Notifications enabled
      failed: You didn't enable notifications
  instruction_hint: Everything you need can be configured in your profile.
  instruction_to_profile: Profile
  want_sex_updating_success: Your wish has been updated successfully
  want_sex_updating_error: An error occurred while updating the wish
  partner_want: desire
  partner_not_ready: ready
  unlock: Unlock.
  your_status_with_message: 'You suggest to your partner:'
  partner_status_with_message: 'The partner suggests trying:'
  send_idea: Send an idea.
  idea_placeholder: Write your idea to your partner.
  idea_selection: 'Or choose from your coincidences of interests:'
  idea_empty: You do not have a marked coincidence of interests with a partner
  idea_add: Add interests
  back: Back.
  message:
    idea_title: Send your chosen idea to your partner?
    idea_custom: 'Own idea: {content}'
    idea_selected: 'Coincidence of interests: {content}'
    send: Send.
    cancel: Cancel.

ru:
  title: Статус желания
  want: желаете
  not_ready: не готовы
  partner_want: желает
  partner_not_ready: не готов
  you_want_sex:
    - Вы
    - заняться сексом
  you_not_ready_for_sex:
    - Вы
    - заняться сексом
  your_partner_wants_sex:
    - Ваш партнер
    - заняться сексом
  your_partner_is_not_ready_for_sex:
    - Ваш партнер
    - на секс
  you: Вы
  partner: Партнер
  your_status_with_message: 'Вы предлагаете партнеру:'
  partner_status_with_message: 'Партнер предлагает попробовать:'
  send_idea: Отправить идею
  idea_placeholder: Напишите вашу идею партнеру
  idea_selection: 'Или выберите из ваших совпадений интересов:'
  idea_empty: У вас нет отмеченных совпадений интересов с партнером
  idea_add: Добавить интересы
  back: Назад
  change_want_sex: 'Изменить статус желания:'
  close: Закрыть
  unlock: Разблокировать
  instruction_title: Как это работает
  instruction_description: Статус желания - это возможность сообщить вашему партнеру о вашем желании заняться сегодня
    сексом. Когда вы сделаете свой статус активным, ваш партнер получит уведомление об этом на своем устройстве.
  instruction_actions_title: 'Необходимые действия:'
  instruction_actions:
    - success: Партнер добавлен
      failed: Вы не добавили партнера
    - success: Уведомления включены
      failed: Вы не включили уведомления
  instruction_hint: Все необходимое можно настроить в вашем профиле.
  instruction_to_profile: Профиль
  want_sex_updating_success: Ваше желание успешно обновлено
  want_sex_updating_error: Во время обновления желания произошла ошибка
  message:
    idea_title: Отправить выбранную вами идею партнеру?
    idea_custom: 'Собственная идея: {content}'
    idea_selected: 'Совпадение интересов: {content}'
    send: Отправить
    cancel: Отменить

de:
  title: Wunschstatus
  want: möchtest du
  not_ready: nicht bereit
  you_want_sex:
    - Du
    - Sex haben
  you_not_ready_for_sex:
    - Du
    - Sex haben
  your_partner_wants_sex:
    - Ihr Partner
    - Sex haben
  your_partner_is_not_ready_for_sex:
    - Ihr Partner
    - für Sex
  you: Du
  partner: Partner
  change_want_sex: 'Wunschstatus ändern:'
  close: Schließen
  instruction_title: Wie funktioniert das
  instruction_description: Der Wunschstatus ist eine Gelegenheit, Ihrem Partner mitzuteilen, dass Sie heute Sex haben
    möchten. Wenn Sie Ihren Status aktivieren, erhält Ihr Partner eine Benachrichtigung auf seinem Gerät.
  instruction_actions_title: 'Erforderliche Aktionen:'
  instruction_actions:
    - success: Partner hinzugefügt
      failed: Sie haben keinen Partner hinzugefügt
    - success: Benachrichtigungen aktiviert
      failed: Sie haben Benachrichtigungen nicht aktiviert
  instruction_hint: Alles, was Sie brauchen, können Sie in Ihrem Profil konfigurieren.
  instruction_to_profile: Profil
  want_sex_updating_success: Ihr Wunsch wurde erfolgreich aktualisiert
  want_sex_updating_error: Beim Aktualisieren des Wunsches ist ein Fehler aufgetreten.
  partner_want: Wunsch
  partner_not_ready: fertig
  unlock: Entsperren.
  your_status_with_message: 'Sie empfehlen Ihrem Partner:'
  partner_status_with_message: 'Der Partner schlägt vor, zu versuchen:'
  send_idea: Schicken Sie eine Idee.
  idea_placeholder: Schreiben Sie Ihre Idee an Ihren Partner.
  idea_selection: 'Oder wählen Sie aus Ihren Interessenszufällen:'
  idea_empty: Sie haben kein ausgeprägtes Interesse mit einem Partner
  idea_add: Interessen hinzufügen
  back: Zurück.
  message:
    idea_title: Senden Sie Ihre gewählte Idee an Ihren Partner?
    idea_custom: 'Eigene Idee: {content}'
    idea_selected: 'Interesse: {content}'
    send: Schicken Sie.
    cancel: Abbrechen.

es:
  title: estado de deseo
  want: te gustaria
  not_ready: no listo
  you_want_sex:
    - Tú
    - tener relaciones sexuales
  you_not_ready_for_sex:
    - Tú
    - tener relaciones sexuales
  your_partner_wants_sex:
    - Tu pareja
    - tener relaciones sexuales
  your_partner_is_not_ready_for_sex:
    - Tu pareja
    - para el sexo
  you: Tú
  partner: Pareja
  change_want_sex: 'Cambiar el estado del deseo:'
  close: Cerca
  instruction_title: Cómo funciona esto
  instruction_description: El estado de deseo es una oportunidad para comunicarle a tu pareja que quieres tener relaciones
    sexuales hoy. Cuando active su estado, su socio recibirá una notificación en su dispositivo.
  instruction_actions_title: 'Acciones requeridas:'
  instruction_actions:
    - success: Socio agregado
      failed: No has agregado un socio
    - success: Notificaciones habilitadas
      failed: No has activado las notificaciones
  instruction_hint: Todo lo que necesitas lo puedes configurar en tu perfil.
  instruction_to_profile: Perfil
  want_sex_updating_success: Tu deseo ha sido actualizado exitosamente
  want_sex_updating_error: Se produjo un error al actualizar el deseo.
  partner_want: deseo
  partner_not_ready: listo
  unlock: Desbloquear.
  your_status_with_message: 'Sugiere a su pareja:'
  partner_status_with_message: 'El socio sugiere probar:'
  send_idea: Envía una idea.
  idea_placeholder: Escribe tu idea a tu compañero.
  idea_selection: 'O elegir de sus coincidencias de intereses:'
  idea_empty: Usted no tiene una marcada coincidencia de intereses con un socio
  idea_add: Agregar intereses
  back: Atrás.
  message:
    idea_title: ¿Enviar tu idea elegida a tu pareja?
    idea_custom: 'Su propia idea: {content}'
    idea_selected: 'Coincidencia de intereses: {content}'
    send: Enviar.
    cancel: Cancelar.

fr:
  title: Statut de désir
  want: Voudrais-tu
  not_ready: pas prêt
  you_want_sex:
    - Toi
    - faire l'amour
  you_not_ready_for_sex:
    - Toi
    - faire l'amour
  your_partner_wants_sex:
    - Votre partenaire
    - faire l'amour
  your_partner_is_not_ready_for_sex:
    - Votre partenaire
    - pour le sexe
  you: Toi
  partner: Partenaire
  change_want_sex: 'Modifier le statut du souhait :'
  close: Fermer
  instruction_title: Comment cela marche-t-il
  instruction_description: Le statut de désir est une opportunité de communiquer à votre partenaire que vous souhaitez
    avoir des relations sexuelles aujourd'hui. Lorsque vous activez votre statut, votre partenaire recevra une
    notification sur son appareil.
  instruction_actions_title: 'Actions requises :'
  instruction_actions:
    - success: Partenaire ajouté
      failed: Vous n'avez pas ajouté de partenaire
    - success: Notifications activées
      failed: Vous n'avez pas activé les notifications
  instruction_hint: Tout ce dont vous avez besoin peut être configuré dans votre profil.
  instruction_to_profile: Profil
  want_sex_updating_success: Votre souhait a été mis à jour avec succès
  want_sex_updating_error: Une erreur s'est produite lors de la mise à jour du souhait.
  partner_want: désir
  partner_not_ready: Prêt
  unlock: Débloquer.
  your_status_with_message: 'Vous suggérez à votre partenaire:'
  partner_status_with_message: "Le partenaire suggère d'essayer:"
  send_idea: Envoyez une idée.
  idea_placeholder: Écrivez votre idée à votre partenaire.
  idea_selection: "Ou choisissez parmi vos coïncidences d'intérêts :"
  idea_empty: Vous n'avez pas une coïncidence marquée d'intérêts avec un partenaire
  idea_add: Ajouter des intérêts
  back: En arrière.
  message:
    idea_title: Envoyer votre idée choisie à votre partenaire?
    idea_custom: 'Idée personnelle : {content}'
    idea_selected: 'Coincidence des intérêts: {content}'
    send: Envoyez.
    cancel: Annuler.
</i18n>

<style lang="scss">
.sexStatusWidget {
  &__toggle {
    padding: 0;
    border: 0 !important;
    border-radius: 50%;
  }

  &__dialog {
    --success-color: #9fdaa8;
    --error-color: #f19ea6;
  }
}
</style>
