<script lang="ts" setup>
import background from '~/assets/images/backgrounds/install_application.jpg';

const { t } = useI18n();

const deferredPrompt = ref();
const available = ref(false);

const onClick = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();

    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt.value = null;
    }
  }
};

onMounted(() => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();

    deferredPrompt.value = e;
    available.value = !!e;
  });
});
</script>

<template>
  <section
    class="relative py-16 p-section overflow-hidden"
    :class="{ hidden: !available }"
  >
    <NuxtImg
      :src="background"
      width="1000"
      height="667"
      sizes="md:100vw lg:100vw xl:100vw xxl:100vw"
      format="avif"
      loading="lazy"
      :alt="t('preview_alt')"
      class="page-image-preview"
    />
    <div class="page-overflow-6 section-top-shadow section-bottom-shadow" />

    <div class="lg:container text-center my-6 relative z-1">
      <h2
        data-show-on-visible
        class="text-2xl md:text-5xl text-800 text-center mb-8 mt-8 relative z-1"
      >
        {{ t('title') }}
      </h2>
      <p
        data-show-on-visible
        class="mt-0 mb-12 lg:text-lg leading-6 text-center mx-auto text-white"
      >
        {{ t('description') }}
      </p>
      <div data-show-on-visible>
        <Button @click="onClick" rounded size="large" class="px-8">
          {{ t('action') }}
        </Button>
        <p class="text-xs mt-5 mb-8 leading-6 text-white">
          {{ t('hint') }}
        </p>
      </div>
      <!--      <div class="flex justify-center items-center">-->
      <!--        <a href="https://www.apple.com" class="text-white mr-3">-->
      <!--          <i class="pi pi-apple text-2xl" /></a><a href="https://play.google.com" class="text-white mr-3"><i class="pi pi-android text-2xl" /></a><a href="https://www.facebook.com" class="text-white"><i class="pi pi-facebook text-2xl" /></a>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<i18n>
en:
  preview_alt: Background for the section with the installation of an erotic application
  title: Install App
  description: Install the application on your device and get unlimited pleasure from our site even faster.
  action: Install
  hint: Works in Chrome, Safari and other browsers

ru:
  preview_alt: Фон для секции с установкой эротического приложения
  title: Установите приложение
  description: Установите приложение себе на устройство и получите безграничное удовольствие нашего сайта еще быстрее.
  action: Установить
  hint: Работает в Chrome, Safari и других браузерах

de:
  preview_alt: Hintergrund zum Abschnitt mit der Installation einer Erotikanwendung
  title: Installieren Sie die Anwendung
  description: Installieren Sie die Anwendung auf Ihrem Gerät und genießen Sie noch schneller uneingeschränktes Vergnügen
    auf unserer Website.
  action: Installieren
  hint: Funktioniert in Chrome, Safari und anderen Browsern

es:
  preview_alt: Antecedentes de la sección con la instalación de una aplicación erótica.
  title: Instalar la aplicación
  description: Instale la aplicación en su dispositivo y obtenga placer ilimitado de nuestro sitio aún más rápido.
  action: Instalar
  hint: Funciona en Chrome, Safari y otros navegadores

fr:
  preview_alt: Contexte de la section avec l'installation d'une application érotique
  title: Installer l'application
  description: Installez l'application sur votre appareil et profitez encore plus rapidement d'un plaisir illimité sur notre site.
  action: Installer
  hint: Fonctionne dans Chrome, Safari et autres navigateurs
</i18n>
