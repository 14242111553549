export default defineNuxtRouteMiddleware((to) => {
  const authorized = useCookie('authorized');

  if (
    authorized.value &&
    !to.query.force &&
    to.meta.landing &&
    (to.path === '/' || import.meta.server)
  ) {
    return navigateTo('/dashboard', { redirectCode: 302 });
  }
});
