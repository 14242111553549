<script setup lang="ts">
import background from '~/assets/images/backgrounds/profile.jpg';
import { Sex } from '~/types/users';

await useCheckAuthorization();
const config = useRuntimeConfig();
const route = useRoute();
const store = useMainStore();
const verificationStore = useEmailVerificationStore();
const firebase = useFirebaseStore();
const { t } = useI18n();
const toast = useCustomToast();
const confirm = useCustomConfirm();

const SEXES = [
  { label: t('boy'), value: Sex.male },
  { label: t('girl'), value: Sex.female },
];

const notificationInfoDialog = ref(false);
const isPartnerSetupDialogVisible = ref(false);
const isTwoFactorAuthenticationDialogVisible = ref(false);
const isChangePasswordDialogVisible = ref(false);
const isPasskeySettingDialogVisible = ref(false);
const notificationLoading = ref(false);

const saveUser = () => {
  const { email, name, sex, isEmailNotificationsEnabled } = store.user;

  ProfileAPI.update({ body: { email, name, sex, isEmailNotificationsEnabled } })
    .then(() => {
      toast.add({
        severity: 'success',
        summary: t('success_data_updating'),
        life: 3000,
      });
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('error_data_updating'),
        life: 3000,
      });
    });
};

const saveNotification = async () => {
  if (store.notification) {
    try {
      if (notificationLoading.value) return;
      notificationLoading.value = true;

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') throw new Error('permission not granted');

      await firebase
        .update(true)
        .then(() => {
          toast.add({
            severity: 'success',
            summary: t('success_switch_notification_updating'),
            life: 3000,
          });
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: t('error_switch_notification_updating'),
            life: 3000,
          });
        });
    } catch {
      store.notification = false;
      toast.add({
        severity: 'error',
        summary: t('blocked_switch_notification_updating'),
        life: 3000,
      });
      return;
    } finally {
      notificationLoading.value = false;
    }
  } else if (firebase.token) {
    // TODO(updateNotification): вызывается дважды
    await ProfileAPI.updateNotification({
      body: { token: firebase.token, value: false },
    });
  }
};

const onBlur = (e: FocusEvent, key: 'name' | 'email') => {
  const newValue = (e.target as HTMLInputElement).value;
  if (newValue && newValue !== store.user[key]) {
    store.user[key] = newValue;
    saveUser();
  }
};

const onToggle = (key: Sex) => {
  store.user.sex = key;
  saveUser();
};

const cancelInvite = () => {
  confirm.require({
    header: t('cancel_invite_title'),
    message: t('cancel_invite_description'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('yes'),
    rejectLabel: t('no'),
    accept: () => {
      ProfileAPI.cancelInvite().then(() => {
        store.user.pairedUser = null;
        toast.add({
          severity: 'success',
          summary: t('success_cancel_invite'),
          life: 3000,
        });
      });
    },
  });
};

const checkToggleWantSex = (event: Event) => {
  if (!store.pro) {
    event.preventDefault();
    event.stopPropagation();
    store.isSubscriptionDialogVisible = true;
    return;
  }
};

const toggleWantSex = () => {
  if (!store.pro) return;

  store.user.wantSex = !store.user.wantSex;

  store
    .saveWantSex()
    .then(() => {
      toast.add({
        severity: 'success',
        summary: t('want_sex_updating_success'),
        life: 3000,
      });
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('want_sex_updating_error'),
        life: 3000,
      });
    });
};

watch(
  () => store.user.pairedUser,
  (v) => {
    if (v) isPartnerSetupDialogVisible.value = false;
  },
);

onMounted(() => {
  if (route.query.verifyEmail && !store.user.isEmailVerified)
    verificationStore.sendVerificationEmail();
});

// TODO: кажется лишнее дублирование определения и можно проще
definePageMeta({
  authGuard: true,
  middleware: ['auth'],
});

useSchemaOrg([
  defineWebPage({
    name: t('schema_title'),
    datePublished: new Date(2022, 4, 27, 20).toISOString(),
  }),
]);
</script>

<template>
  <main class="flex flex-col flex-1" data-test-id="profile-page">
    <Head>
      <Title>{{ t('meta.profile.title') }}</Title>
      <Meta property="og:title" :content="t('meta.profile.title')" />
    </Head>

    <div
      class="flex flex-1 items-start relative min-h-80vh pt-20 pb-3 p-section"
    >
      <BasePageBackgroundImage :image="background" />
      <div class="page-overflow-7" />

      <div
        class="lg:container grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 z-3 relative w-full py-7"
      >
        <Card
          class="w-full h-full"
          :pt="{ body: 'h-full', content: 'h-full !p-0' }"
        >
          <template #title>{{ t('editing_title') }}</template>

          <template #content>
            <BaseToggleTransition>
              <div
                v-if="!store.loaded"
                class="flex items-center justify-center p-8"
              >
                <i class="text-xl pi pi-spin pi-spinner" />
              </div>

              <div v-else class="flex flex-col gap-3 h-full pt-3">
                <label class="block w-full">
                  {{ t('editing_name_label') }}
                  <InputText
                    @blur="onBlur($event, 'name')"
                    :value="store.user.name"
                    size="large"
                    class="w-full my-2"
                    :placeholder="t('editing_name_placeholder')"
                    autocomplete="given-name"
                    data-test-id="name-input"
                  />
                </label>
                <label class="block w-full">
                  {{ t('editing_email_label') }}
                  <IconField
                    v-if="!store.user.isEmailVerified"
                    class="block w-full my-2"
                  >
                    <InputText
                      @blur="onBlur($event, 'email')"
                      :placeholder="t('editing_email_placeholder')"
                      size="large"
                      class="w-full"
                      :class="{ 'p-invalid': !store.user.isEmailVerified }"
                      name="email"
                      autocomplete="email"
                      :value="store.user.email"
                      data-test-id="email-input"
                    />
                    <InputIcon
                      class="pi pi-exclamation-circle"
                      style="color: #f19ea6"
                    />
                  </IconField>
                  <InputText
                    v-else
                    @blur="onBlur($event, 'email')"
                    :placeholder="t('editing_email_placeholder')"
                    size="large"
                    class="block w-full my-2"
                    :class="{ 'p-invalid': !store.user.isEmailVerified }"
                    name="email"
                    autocomplete="email"
                    :value="store.user.email"
                    data-test-id="email-input"
                  />
                </label>
                <Message
                  v-if="store.isAuthorized && !store.user.isEmailVerified"
                  severity="warn"
                  :closable="false"
                  class="profilePage__alert mt-0"
                  :pt="{ text: 'flex-1' }"
                >
                  <div class="flex items-center justify-between">
                    <p class="m-0">
                      {{ t('confirm_email_title') }}
                      <small class="block mt-2">
                        {{ t('confirm_email_benefit.0') }}
                        <b>Pro</b>
                        {{ t('confirm_email_benefit.1') }}
                      </small>
                    </p>
                    <Button
                      @click="verificationStore.sendVerificationEmail"
                      icon="pi pi-send"
                      rounded
                      class="flex-shrink-0 ml-2"
                      :loading="verificationStore.isVerificationEmailRequest"
                    />
                  </div>
                </Message>
                <Message
                  v-if="verificationStore.isVerificationEmailSent"
                  severity="info"
                  class="mt-0"
                  :closable="false"
                >
                  {{ t('email_sent') }}
                </Message>
                {{ t('editing_sex_label') }}
                <ButtonGroup
                  data-test-id="sex-input"
                  :data-selected="store.user.sex"
                >
                  <Button
                    v-for="item in SEXES"
                    :key="item.value"
                    @click="onToggle(item.value)"
                    size="large"
                    :outlined="store.user.sex !== item.value"
                    class="w-1/2 justify-center"
                  >
                    {{ item.label }}
                  </Button>
                </ButtonGroup>

                <template v-if="store.isAuthorized">
                  <Divider />

                  <template v-if="store.user.pairedUser">
                    <div class="text-800 text-lg m-0 flex items-center">
                      {{ t('editing_your_paired_user') }}
                      {{ store.user.pairedUser.name }}
                      <Button
                        severity="danger"
                        outlined
                        icon="pi pi-times"
                        class="ml-auto"
                        @click="cancelInvite"
                      />
                    </div>
                  </template>
                  <template v-else>
                    {{ t('editing_invite_paired_user_message') }}
                    <Button
                      class="justify-center mt-2"
                      @click="isPartnerSetupDialogVisible = true"
                    >
                      {{ t('editing_invite_paired_user_action') }}
                    </Button>

                    <LazyDialogPartnerSetup
                      v-model:visible="isPartnerSetupDialogVisible"
                    />
                  </template>
                </template>

                <Divider />

                <span class="flex items-center">
                  <span class="mr-2 flex-shrink-1 text-base xl:text-lg flex-1">
                    {{ t('settings_want_sex') }}
                  </span>
                  <ToggleSwitch
                    class="ml-auto"
                    :model-value="store.user.wantSex"
                    @update:model-value="toggleWantSex"
                    @change.capture="checkToggleWantSex"
                  />
                </span>
              </div>
            </BaseToggleTransition>
          </template>
        </Card>

        <Card
          class="w-full h-full"
          :pt="{ body: 'h-full', content: 'h-full !p-0' }"
        >
          <template #title>{{ t('settings_title') }}</template>

          <template #content>
            <BaseToggleTransition class="h-full">
              <div
                v-if="!store.loaded"
                class="flex items-center justify-center p-8"
              >
                <i class="text-xl pi pi-spin pi-spinner" />
              </div>

              <div v-else class="flex flex-col gap-3 h-full pt-3">
                <Button
                  as="nuxt-link"
                  to="/interests/change"
                  :label="t('settings_change_interests')"
                  outlined
                  class="justify-center w-full"
                />

                <Divider />

                <span v-if="firebase.supported" class="flex items-center">
                  <span class="mr-2 flex-shrink-1 text-base xl:text-lg flex-1">
                    {{ t('settings_switch_push_notification') }}
                  </span>
                  <span class="flex items-center gap-2">
                    <i
                      v-if="notificationLoading"
                      class="pi pi-spin pi-spinner text-primary-400"
                    />
                    <Button
                      @click="notificationInfoDialog = true"
                      icon="pi pi-question-circle"
                      text
                      rounded
                      class="w-1.5rem h-1.5rem"
                    />
                    <ToggleSwitch
                      v-model="store.notification"
                      @change="saveNotification"
                      :disabled="notificationLoading"
                      class="ml-auto"
                    />
                  </span>
                </span>
                <span class="flex items-center">
                  <span class="mr-2 flex-shrink-1 text-base xl:text-lg flex-1">
                    {{ t('settings_switch_email_notification') }}
                  </span>
                  <span class="flex items-center gap-2">
                    <Button
                      @click="notificationInfoDialog = true"
                      icon="pi pi-question-circle"
                      text
                      rounded
                      class="w-1.5rem h-1.5rem"
                    />
                    <ToggleSwitch
                      v-model="store.user.isEmailNotificationsEnabled"
                      @change="saveUser"
                      class="ml-auto"
                    />
                  </span>
                </span>

                <Divider />

                <div class="flex flex-col gap-3 mt-auto">
                  <!-- TODO: в идеале мотивировать делать обе настройки безопасности -->
                  <Message
                    v-if="!store.user.isTwoFactorAuthenticationEnabled"
                    severity="warn"
                    :closable="false"
                  >
                    <template #icon>
                      <i class="pi pi-exclamation-triangle text-xl" />
                    </template>

                    {{ t('warning_2fa_auth') }}
                  </Message>
                  <Button
                    @click="isTwoFactorAuthenticationDialogVisible = true"
                    :label="t('two_factor_setup')"
                    outlined
                    class="justify-center w-full"
                  />
                  <Button
                    v-if="store.isPasskeyEnabled"
                    @click="isPasskeySettingDialogVisible = true"
                    :label="t('editing_setup_passkey')"
                    outlined
                    class="justify-center"
                  />
                  <Button
                    @click="isChangePasswordDialogVisible = true"
                    :label="t('editing_change_password')"
                    outlined
                    class="justify-center"
                  />
                </div>

                <Divider />

                <Button
                  severity="danger"
                  outlined
                  class="justify-center w-full mt-auto"
                  @click="store.logout()"
                >
                  {{ t('settings_logout') }}
                </Button>
              </div>
            </BaseToggleTransition>
          </template>
        </Card>
      </div>
    </div>

    <LayoutFooter />

    <DialogChangePassword v-model:visible="isChangePasswordDialogVisible" />
    <DialogTwoFactorSetup
      v-model:visible="isTwoFactorAuthenticationDialogVisible"
    />
    <DialogPasskeySetting v-model:visible="isPasskeySettingDialogVisible" />

    <Dialog
      :header="t('notification_dialog_title')"
      v-model:visible="notificationInfoDialog"
      dismissable-mask
      :base-z-index="5"
      :draggable="false"
      modal
      class="w-104"
    >
      <div class="text-900">
        <p class="mt-0 mb-3 leading-6">
          {{ t('notification_dialog_description') }}
        </p>
        <p class="mt-0 mb-2 leading-6">
          {{ t('notification_dialog_list_title') }}
        </p>
        <ul class="m-0 pl-3 text-sm">
          <li class="leading-6">
            {{ t('notification_dialog_list.0') }}
          </li>
        </ul>
      </div>

      <template #footer>
        <Button
          @click="notificationInfoDialog = false"
          :label="t('notification_dialog_close')"
          icon="pi pi-times"
          autofocus
          text
        />
      </template>
    </Dialog>
  </main>
</template>

<i18n src="~/locales/meta.yaml"></i18n>
<i18n>
en:
  schema_title: Personal user profile
  boy: Boy
  girl: Girl
  cancel_invite_title: Unlinking the partner
  cancel_invite_description: Are you sure you want to unlink the partner?
  success_cancel_invite: Partner is unlinked
  editing_title: Profile editing
  editing_name_label: Name
  editing_name_placeholder: Enter your name
  editing_email_label: Email
  editing_email_placeholder: Enter your email
  editing_sex_label: Gender
  editing_your_paired_user: 'Your partner:'
  editing_change_password: Change password
  settings_title: Settings
  settings_change_interests: Change interests
  settings_want_sex: 'Do you want to have sex today:'
  settings_switch_push_notification: 'Push notifications:'
  settings_switch_email_notification: 'Email notifications:'
  settings_pro_label_prefix: Sex Play
  settings_pro_buy: Get
  settings_logout: Logout
  notification_dialog_title: What will be the notifications
  notification_dialog_description: We send only the most necessary notifications. There will be no advertisements.
  notification_dialog_list_title: 'Notification types:'
  notification_dialog_list:
    - Changing the status of your partner's desire for sex.
  notification_dialog_close: Close
  success_data_updating: Data updated successfully
  error_data_updating: An error occurred while updating data
  success_switch_notification_updating: Notifications turned on successfully
  error_switch_notification_updating: An error occurred while enabling notifications
  blocked_switch_notification_updating: Notifications can't be enabled unless you allow them
  yes: Yes
  no: No
  confirm_email_title: Please Confirm Email
  confirm_email_benefit:
    - And get
    - week free!
  email_sent: An email has been sent to your email
  want_sex_updating_success: Your wish has been updated successfully
  want_sex_updating_error: An error occurred while updating the wish
  editing_invite_paired_user_message: Add your partner to play co-op games, communicate your desires and more!
  editing_invite_paired_user_action: Invite a partner
  editing_setup_passkey: Entrance by biometrics
  warning_2fa_auth: Set up two-factor authentication for your account security
  two_factor_setup: Two-step authentication

ru:
  schema_title: Личный профиль пользователя
  boy: Парень
  girl: Девушка
  cancel_invite_title: Отмена привязки партнера
  cancel_invite_description: Вы уверены, что хотите отменить привязку партнера?
  success_cancel_invite: Привязка партнера отменена
  editing_title: Редактирование профиля
  editing_name_label: Имя
  editing_name_placeholder: Введите ваше имя
  editing_email_label: Email
  editing_email_placeholder: Введите email
  editing_sex_label: Пол
  editing_invite_paired_user_message: Добавьте своего партнера чтобы играть в совместные игры, общаться о своих желаниях и многое другое!
  editing_invite_paired_user_action: Пригласить партнера
  editing_your_paired_user: 'Ваш партнер:'
  editing_setup_passkey: Вход по биометрии
  editing_change_password: Изменить пароль
  settings_title: Настройки
  settings_change_interests: Изменить интересы
  settings_want_sex: 'Желаете ли вы сегодня заняться сексом:'
  settings_switch_push_notification: 'Push уведомления:'
  settings_switch_email_notification: 'Email уведомления:'
  settings_pro_label_prefix: Sex Play
  settings_pro_buy: Получить
  settings_logout: Выйти
  notification_dialog_title: Какие будут уведомления
  notification_dialog_description: Мы присылаем только самые необходимые уведомления. Никаких рекламных рассылок не будет.
  notification_dialog_list_title: 'Виды уведомлений:'
  notification_dialog_list:
    - Обновление статуса желания секса вашего партнера.
  notification_dialog_close: Закрыть
  success_data_updating: Данные успешно обновлены
  error_data_updating: Во время обновления данных произошла ошибка
  success_switch_notification_updating: Уведомления успешно включены
  error_switch_notification_updating: Во время включения уведомлений произошла ошибка
  blocked_switch_notification_updating: Уведомления невозможно включить, если вы их не разрешите
  yes: Да
  no: Нет
  confirm_email_title: Пожалуйста, подтвердите Email
  confirm_email_benefit:
    - И получите неделю
    - бесплатно!
  email_sent: Письмо отправлено на ваш Email
  want_sex_updating_success: Ваше желание успешно обновлено
  want_sex_updating_error: Во время обновления желания произошла ошибка
  warning_2fa_auth: Настройте двухфакторную аутентификацию для безопасности вашего аккаунта
  two_factor_setup: Двухэтапная аутентификация

de:
  schema_title: Persönliches Benutzerprofil
  boy: Junge
  girl: Junge Frau
  cancel_invite_title: Verknüpfung eines Partners aufheben
  cancel_invite_description: Möchten Sie die Verknüpfung mit Ihrem Partner wirklich aufheben?
  success_cancel_invite: Partnerbindung abgebrochen
  editing_title: Bearbeiten Ihres Profils
  editing_name_label: Name
  editing_name_placeholder: Geben Sie Ihren Namen ein
  editing_email_label: E-Mail
  editing_email_placeholder: Geben Sie die E-Mail-Adresse ein
  editing_sex_label: Boden
  editing_your_paired_user: 'Ihr Partner:'
  editing_change_password: Kennwort ändern
  settings_title: Einstellungen
  settings_change_interests: Interessen ändern
  settings_want_sex: 'Möchtest du heute Sex haben:'
  settings_switch_push_notification: 'Push-Benachrichtigungen:'
  settings_switch_email_notification: 'E-Mail-Benachrichtigungen:'
  settings_pro_label_prefix: Sex Play
  settings_pro_buy: Erhalten
  settings_logout: Abmelden
  notification_dialog_title: Welche Benachrichtigungen wird es geben?
  notification_dialog_description: Wir versenden nur die notwendigsten Benachrichtigungen. Es werden keine Werbemailings verschickt.
  notification_dialog_list_title: 'Arten von Benachrichtigungen:'
  notification_dialog_list:
    - Aktualisieren Sie den Sexwunschstatus Ihres Partners.
  notification_dialog_close: Schließen
  success_data_updating: Daten erfolgreich aktualisiert
  error_data_updating: Beim Aktualisieren der Daten ist ein Fehler aufgetreten
  success_switch_notification_updating: Benachrichtigungen erfolgreich aktiviert
  error_switch_notification_updating: Beim Aktivieren von Benachrichtigungen ist ein Fehler aufgetreten
  blocked_switch_notification_updating: Benachrichtigungen können nur aktiviert werden, wenn Sie dies zulassen.
  yes: Ja
  no: NEIN
  confirm_email_title: Bitte bestätigen Sie Ihre E-Mail
  confirm_email_benefit:
    - Und nimm dir eine Woche
    - kostenlos!
  email_sent: Der Brief wurde an Ihre E-Mail-Adresse gesendet
  want_sex_updating_success: Ihr Wunsch wurde erfolgreich aktualisiert
  want_sex_updating_error: Beim Aktualisieren des Wunsches ist ein Fehler aufgetreten.
  editing_invite_paired_user_message: Fügen Sie Ihren Partner um Co-op-Spiele zu spielen, kommunizieren Sie Ihre Wünsche und mehr!
  editing_invite_paired_user_action: Einen Partner einladen
  editing_setup_passkey: Eingang durch Biometrie
  warning_2fa_auth: Zwei-Faktor-Authentifizierung für Ihre Kontosicherheit einrichten
  two_factor_setup: Zweistufige Authentifizierung

es:
  schema_title: Perfil de usuario personal
  boy: Chico
  girl: Joven
  cancel_invite_title: Desvincular a un socio
  cancel_invite_description: ¿Estás seguro de que quieres desvincular a tu pareja?
  success_cancel_invite: Enlace de socio cancelado
  editing_title: Editando tu perfil
  editing_name_label: Nombre
  editing_name_placeholder: Introduce tu nombre
  editing_email_label: Correo electrónico
  editing_email_placeholder: Ingrese el correo electrónico
  editing_sex_label: Piso
  editing_your_paired_user: 'Tu socio:'
  editing_change_password: Cambiar la contraseña
  settings_title: Ajustes
  settings_change_interests: Cambiar intereses
  settings_want_sex: ¿Te gustaría tener sexo hoy?
  settings_switch_push_notification: 'Notificaciones push:'
  settings_switch_email_notification: 'Notificaciones por correo electrónico:'
  settings_pro_label_prefix: Sex Play
  settings_pro_buy: Conseguir
  settings_logout: Finalizar la sesión
  notification_dialog_title: ¿Qué notificaciones habrá?
  notification_dialog_description: Enviamos solo las notificaciones más necesarias. No habrá envíos publicitarios.
  notification_dialog_list_title: 'Tipos de notificaciones:'
  notification_dialog_list:
    - Actualice el estado del deseo sexual de su pareja.
  notification_dialog_close: Cerca
  success_data_updating: Datos actualizados exitosamente
  error_data_updating: Se produjo un error al actualizar los datos.
  success_switch_notification_updating: Notificaciones habilitadas exitosamente
  error_switch_notification_updating: Se produjo un error al habilitar las notificaciones.
  blocked_switch_notification_updating: Las notificaciones no se pueden activar a menos que usted las permita.
  yes: Sí
  no: No
  confirm_email_title: Por favor confirma tu correo electrónico
  confirm_email_benefit:
    - Y consigue una semana
    - ¡gratis!
  email_sent: La carta ha sido enviada a su correo electrónico.
  want_sex_updating_success: Tu deseo ha sido actualizado exitosamente
  want_sex_updating_error: Se produjo un error al actualizar el deseo.
  editing_invite_paired_user_message: ¡Añada a su pareja a jugar juegos de coop, comunique sus deseos y más!
  editing_invite_paired_user_action: Invitar a un socio
  editing_setup_passkey: Entrada por biometría
  warning_2fa_auth: Configurar la autenticación de dos factores para la seguridad de tu cuenta
  two_factor_setup: autenticación de dos pasos

fr:
  schema_title: Profil utilisateur personnel
  boy: Garçon
  girl: Jeune femme
  cancel_invite_title: Dissocier un partenaire
  cancel_invite_description: Êtes-vous sûr de vouloir dissocier votre partenaire ?
  success_cancel_invite: Liaison du partenaire annulée
  editing_title: Modification de votre profil
  editing_name_label: Nom
  editing_name_placeholder: Entrez votre nom
  editing_email_label: E-mail
  editing_email_placeholder: Entrez l'e-mail
  editing_sex_label: Sol
  editing_your_paired_user: 'Votre partenaire :'
  editing_change_password: Changer le mot de passe
  settings_title: Paramètres
  settings_change_interests: Changer d'intérêt
  settings_want_sex: "Aimeriez-vous faire l'amour aujourd'hui :"
  settings_switch_push_notification: 'Notifications poussées :'
  settings_switch_email_notification: 'Notifications par e-mail :'
  settings_pro_label_prefix: Sex Play
  settings_pro_buy: Obtenir
  settings_logout: Se déconnecter
  notification_dialog_title: Quelles notifications y aura-t-il ?
  notification_dialog_description: Nous envoyons uniquement les notifications les plus nécessaires. Il n’y aura pas d’envois publicitaires.
  notification_dialog_list_title: 'Types de notifications :'
  notification_dialog_list:
    - Mettez à jour le statut de désir sexuel de votre partenaire.
  notification_dialog_close: Fermer
  success_data_updating: Données mises à jour avec succès
  error_data_updating: Une erreur s'est produite lors de la mise à jour des données
  success_switch_notification_updating: Notifications activées avec succès
  error_switch_notification_updating: Une erreur s'est produite lors de l'activation des notifications
  blocked_switch_notification_updating: Les notifications ne peuvent pas être activées sauf si vous les autorisez.
  yes: Oui
  no: Non
  confirm_email_title: Veuillez confirmer votre email
  confirm_email_benefit:
    - Et bénéficie d'une semaine
    - gratuitement !
  email_sent: La lettre a été envoyée à votre Email
  want_sex_updating_success: Votre souhait a été mis à jour avec succès
  want_sex_updating_error: Une erreur s'est produite lors de la mise à jour du souhait.
  editing_invite_paired_user_message: Ajoutez votre partenaire pour jouer à des jeux coopératifs, communiquer vos désirs et plus encore!
  editing_invite_paired_user_action: Inviter un partenaire
  editing_setup_passkey: Entrée par biométrie
  warning_2fa_auth: Configurez l'authentification à deux facteurs pour la sécurité de votre compte
  two_factor_setup: Authentification en deux étapes
</i18n>
