<script lang="ts" setup>
import { AnswerVariant } from '~/types/questions';

const props = defineProps<{
  answer: `${AnswerVariant}`;
}>();

const { t } = useI18n();
</script>

<template>
  <span v-if="props.answer === AnswerVariant.no" style="color: #f19ea6">{{
    t('no')
  }}</span>
  <span
    v-else-if="props.answer === AnswerVariant.maybe"
    style="color: #8dd0ff"
    >{{ t('maybe') }}</span
  >
  <span v-else style="color: #9fdaa8">{{ t('yes') }}</span>
</template>

<i18n>
en:
  yes: Yes
  maybe: Maybe
  no: No

ru:
  yes: Да
  maybe: Может
  no: Нет

de:
  yes: Ja
  maybe: Vielleicht
  no: NEIN

es:
  yes: Sí
  maybe: Tal vez
  no: No

fr:
  yes: Oui
  maybe: Peut être
  no: Non
</i18n>
