<script lang="ts" setup>
import background from '~/assets/images/backgrounds/products.jpg';

const { t } = useI18n();
</script>

<template>
  <section
    class="relative bg-black p-section py-16 lg:py-20 xl:py-28"
    id="products"
  >
    <NuxtImg
      class="page-image-preview hidden md:block"
      :src="background"
      width="1700"
      height="1133"
      sizes="lg:100vw xl:100vw xxl:100vw"
      :alt="t('preview_alt')"
      format="avif"
      loading="lazy"
    />
    <div
      class="page-overflow-7 section-top-shadow section-bottom-shadow hidden md:block"
    />

    <h2
      data-show-on-visible
      class="text-2xl md:text-5xl text-800 text-center mb-16 mt-8 relative z-1"
    >
      {{ t('title') }}
    </h2>
    <div
      class="lg:container grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 z-2 relative"
    >
      <Card
        data-show-on-visible
        class="mb-3 h-full neon-border"
        :pt="{
          body: 'h-full',
          footer: 'flex flex-col sm:flex-row justify-end gap-3 mt-auto',
        }"
      >
        <template #content>
          <div class="flex items-start">
            <span
              class="inline-flex rounded-full items-center justify-center mr-3"
              style="
                flex: 0 0 38px;
                width: 38px;
                height: 38px;
                background-color: var(--p-teal-600);
              "
            >
              <i class="pi pi-heart text-xl" />
            </span>
            <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
              {{ t('tasks_subtitle') }}
            </h3>
          </div>
          <p
            class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium leading-6"
          >
            {{ t('tasks_description') }}
            <br />
            <br />
            {{ t('tasks_rules') }}
          </p>
        </template>

        <template #footer>
          <Button
            as="nuxt-link"
            to="/about-fants"
            rounded
            outlined
            size="large"
            type="button"
            class="w-full sm:w-fit"
          >
            {{ t('find_out_more') }}
          </Button>
          <Button
            as="nuxt-link"
            to="/game/settings"
            rounded
            size="large"
            type="button"
            class="w-full sm:w-fit"
          >
            {{ t('start_the_game') }}
          </Button>
        </template>
      </Card>

      <Card
        data-show-on-visible='{ "delay": 150, "noDelayOnMobile": 768 }'
        class="mb-3 h-full neon-border"
        :pt="{
          body: 'h-full',
          footer: 'flex flex-col sm:flex-row justify-end gap-3 mt-auto',
        }"
      >
        <template #content>
          <div class="flex items-start">
            <span
              class="inline-flex rounded-full items-center justify-center mr-3"
              style="
                flex: 0 0 38px;
                width: 38px;
                height: 38px;
                background-color: var(--p-purple-600);
              "
            >
              <i class="pi pi-question text-xl" />
            </span>
            <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
              {{ t('interests_subtitle') }}
            </h3>
          </div>
          <div
            class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium leading-6"
          >
            <p class="m-0">
              {{ t('interests_description') }}
            </p>
            <ul class="my-2 pl-3">
              <li>{{ t('interests_steps.0') }}</li>
              <li>{{ t('interests_steps.1') }}</li>
              <li>{{ t('interests_steps.2') }}</li>
              <li>{{ t('interests_steps.3') }}</li>
            </ul>
          </div>
        </template>

        <template #footer>
          <Button
            as="nuxt-link"
            to="/about-interests"
            rounded
            outlined
            size="large"
            type="button"
            class="w-full sm:w-fit"
          >
            {{ t('find_out_more') }}
          </Button>
          <Button
            as="nuxt-link"
            to="/interests"
            rounded
            size="large"
            type="button"
            class="w-full sm:w-fit"
          >
            {{ t('go_to_questions') }}
          </Button>
        </template>
      </Card>
    </div>
  </section>
</template>

<i18n>
en:
  title: What you will find on our website
  preview_alt: Uncover new interests of your partner
  tasks_subtitle: Erotic tasks for a couple
  tasks_description: Erotic tasks for a couple - a game designed for those who want to bring spark and passion into their
    sex life, try something new in bed, or just diversify their intimate life.
  tasks_rules: 'The essence of the game is simple: in turn, each opens a card and completes the task. It is important that
    at this moment your partner does not see the tasks, so the game will be more interesting, and the sensations will be
    brighter!'
  interests_subtitle: Uncover new interests of your partner
  interests_description: 'We will help you find new hot ideas for sex. How it works:'
  interests_steps:
    - Both partners connect their accounts;
    - Both partners answer the same sex questions «Yes», «Maybe», «No»;
    - If both partners answered «Yes» or «Maybe», then the question is shown as a match;
    - Any idea that is answered «No» by any of the partners will be hidden.
  find_out_more: More
  start_the_game: Start the game
  go_to_questions: Go to questions

ru:
  title: Что вы у нас найдете
  preview_alt: Раскрыть новые интересы партнера
  tasks_subtitle: Эротические фанты и задания
  tasks_description: Игра в эротические фанты — для желающих внести огонек и страсть в свою сексуальную жизнь, опробовать
    что-то новое в постели, либо просто разнообразить интимную жизнь.
  tasks_rules: 'Суть игры проста: поочередно каждый открывает фант и выполняет задание. Важно, чтобы в этот момент Ваша
    половинка не видела задания, так игра будет интересней, а ощущения ярче!'
  interests_subtitle: Раскрыть новые интересы партнера
  interests_description: 'Поможем найти новые горячие идеи для секса. Как это работает:'
  interests_steps:
    - Оба партнера соединяют свои аккаунты;
    - Оба партнера отвечают одинаковые секс вопросы «Да», «Может», «Нет»;
    - Если оба партнера ответили «Да» или «Может», то вопрос показан как совпадение;
    - Любая идея на которую ответил «Нет» любой из партнеров, будет спрятана.
  find_out_more: Подробнее
  start_the_game: Начать игру
  go_to_questions: К вопросам

de:
  title: Was finden Sie bei uns?
  preview_alt: Entdecken Sie neue Interessen Ihres Partners
  tasks_subtitle: Erotische Verluste und Aufgaben
  tasks_description: Das erotische Spiel ist für diejenigen gedacht, die ihrem Sexualleben Glanz und Leidenschaft
    verleihen, im Bett etwas Neues ausprobieren oder einfach ihr Intimleben abwechslungsreicher gestalten möchten.
  tasks_rules: 'Die Essenz des Spiels ist einfach: Einer nach dem anderen öffnet jeder ein Forfait und erledigt die
    Aufgabe. Es ist wichtig, dass Ihr Lebensgefährte in diesem Moment die Aufgabe nicht sieht. Auf diese Weise wird das
    Spiel interessanter und die Empfindungen intensiver!'
  interests_subtitle: Entdecken Sie neue Interessen Ihres Partners
  interests_description: 'Wir helfen Ihnen dabei, neue heiße Ideen für Sex zu finden. Wie funktioniert das:'
  interests_steps:
    - Beide Partner verbinden ihre Konten;
    - Beide Partner beantworten gleichgeschlechtliche Fragen mit „Ja“, „Vielleicht“, „Nein“;
    - Wenn beide Partner mit „Ja“ oder „Kann“ geantwortet haben, wird die Frage als Übereinstimmung angezeigt;
    - Jede Idee, die von einem der Partner mit „Nein“ beantwortet wird, wird ausgeblendet.
  find_out_more: Weitere Details
  start_the_game: Spiel starten
  go_to_questions: Für Fragen

es:
  title: ¿Qué encontrarás con nosotros?
  preview_alt: Revelar nuevos intereses de tu pareja
  tasks_subtitle: Pérdidas y tareas eróticas.
  tasks_description: El juego de las pérdidas eróticas es para aquellos que quieren añadir brillo y pasión a su vida
    sexual, probar algo nuevo en la cama o simplemente diversificar su vida íntima.
  tasks_rules: 'La esencia del juego es simple: uno por uno, todos abren una multa y completan la tarea. Es importante que
    en este momento tu pareja no vea la tarea, así el juego será más interesante y las sensaciones más vívidas.'
  interests_subtitle: Revelar nuevos intereses de tu pareja
  interests_description: 'Permítanos ayudarle a encontrar nuevas ideas interesantes para el sexo. Cómo funciona esto:'
  interests_steps:
    - Ambos socios conectan sus cuentas;
    - Ambos miembros de la pareja responden a las preguntas del mismo sexo “Sí”, “Quizás”, “No”;
    - Si ambos socios respondieron "Sí" o "Mayo", entonces la pregunta se muestra como coincidente;
    - Cualquier idea a la que cualquiera de los socios responda "No" quedará oculta.
  find_out_more: Más detalles
  start_the_game: iniciar juego
  go_to_questions: Para preguntas

fr:
  title: Que trouverez-vous chez nous ?
  preview_alt: Révélez les nouveaux intérêts de votre partenaire
  tasks_subtitle: Forfaits et tâches érotiques
  tasks_description: Le jeu des forfaits érotiques s'adresse à ceux qui souhaitent ajouter de l'éclat et de la passion à
    leur vie sexuelle, tenter quelque chose de nouveau au lit, ou simplement diversifier leur vie intime.
  tasks_rules: "L'essence du jeu est simple : un par un, chacun ouvre un forfait et accomplit la tâche. Il est important
    qu'à ce moment votre partenaire ne voie pas la tâche, ainsi le jeu sera plus intéressant et les sensations seront
    plus vives !"
  interests_subtitle: Révélez les nouveaux intérêts de votre partenaire
  interests_description: 'Laissez-nous vous aider à trouver de nouvelles idées chaudes pour le sexe. Comment cela marche-t-il:'
  interests_steps:
    - Les deux partenaires connectent leurs comptes ;
    - Les deux partenaires répondent aux questions du même sexe « Oui », « Peut-être », « Non » ;
    - Si les deux partenaires ont répondu « Oui » ou « Peut », alors la question apparaît comme une correspondance ;
    - Toute idée à laquelle l'un ou l'autre des partenaires répondra « Non » sera masquée.
  find_out_more: Plus de détails
  start_the_game: Commencer le jeu
  go_to_questions: Pour des questions
</i18n>
