<script lang="ts" setup>
import { toDataURL } from 'qrcode';

const visible = defineModel<boolean>('visible');

const userStore = useMainStore();
const toast = useCustomToast();
const confirm = useCustomConfirm();
const { t } = useI18n();

const explanationVisible = ref(false);
const code = ref('');
const secret = ref('');
const image = ref('');
const codes = ref<string[]>();
const codeContainerRef = ref<ComponentPublicInstance>();

const { execute, isLoading } = useRequest(ProfileAPI.twoFactorEnable, () => ({
  body: { code: code.value },
}));

const copy = () => {
  if (secret.value) {
    navigator.clipboard.writeText(secret.value).then(() => {
      toast.add({
        severity: 'success',
        summary: t('message.key_copied'),
        life: 3000,
      });
    });
  }
};

const submit = () => {
  execute()
    ?.then((data) => {
      codes.value = data.codes;
      userStore.user.isTwoFactorAuthenticationEnabled = true;
      toast.add({
        severity: 'success',
        summary: t('message.completed'),
        life: 3000,
      });
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('message.check_failed'),
        life: 3000,
      });
    });
};

const cancel = () => {
  confirm.require({
    header: t('message.cancellation_title'),
    message: t('message.cancellation_hint'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('message.cancellation_accept'),
    rejectLabel: t('message.cancellation_decline'),
    accept: () => {
      ProfileAPI.twoFactorDisable().then(() => {
        codes.value = undefined;
        code.value = '';
        image.value = '';
        secret.value = '';
        visible.value = false;
        userStore.user.isTwoFactorAuthenticationEnabled = false;
        toast.add({
          severity: 'info',
          summary: t('message.cancellation_success'),
          life: 3000,
        });
      });
    },
  });
};

const hide = () => {
  code.value = '';
};

watch(visible, (v) => {
  if (v && !secret.value) {
    ProfileAPI.twoFactorGenerate()
      .then((data) => {
        secret.value = data.secret;
        return toDataURL(data.url, { width: 640 });
      })
      .then((data) => {
        image.value = data;
      });
  }
});

watch(
  codeContainerRef,
  (v) => {
    if (v) v.$el.querySelector('input')?.focus();
  },
  { immediate: true },
);
</script>

<template>
  <Dialog
    :header="t('title')"
    :visible="visible"
    @update:visible="visible = $event"
    style="width: 420px"
    dismissable-mask
    :draggable="false"
    modal
    @hide="hide"
  >
    <BaseToggleTransition>
      <div v-if="!userStore.user.isTwoFactorAuthenticationEnabled">
        <div v-auto-animate class="aspect-square">
          <Image
            v-if="image"
            preview
            :src="image"
            class="w-full !block"
            :class="{ 'opacity-50': explanationVisible }"
            image-class="w-full"
          />
        </div>

        <Button
          @click="explanationVisible = true"
          :label="t('detailed')"
          outlined
          class="mt-3 w-full"
        />

        <label class="block w-full mt-4">
          {{ t('key_to_copy') }}
          <InputGroup class="my-2 w-full">
            <InputText
              size="large"
              name="secret"
              v-model="secret"
              autocomplete="off"
              readonly
            />
            <Button @click="copy" icon="pi pi-copy" />
          </InputGroup>
        </label>

        <label class="block w-full mt-3">
          {{ t('code_label') }}
          <InputOtp
            ref="codeContainerRef"
            v-model="code"
            :placeholder="t('code_placeholder')"
            size="large"
            class="my-2 w-full justify-between"
            name="code"
            :length="6"
            integer-only
          />
        </label>
      </div>
      <div v-else class="flex flex-col gap-3">
        <span class="text-lg flex items-start gap-3 leading-4">
          <i class="pi pi-check-circle text-xl mt-1" style="color: #9fdaa8" />
          <span>{{ t('success_title') }}</span>
        </span>

        <div v-if="codes">
          <span class="leading-4">
            {{ t('codes_hint') }}
          </span>

          <div class="grid grid-cols-2 gap-2 mt-3">
            <span v-for="(item, index) in codes" :key="index" class="text-sm">
              {{ item }}
            </span>
          </div>
        </div>

        <Button
          @click="cancel"
          :label="t('delete')"
          outlined
          severity="danger"
          class="mt-4 w-full"
        />
      </div>
    </BaseToggleTransition>

    <template #footer>
      <Transition mode="out-in" name="fade">
        <div v-if="!userStore.user.isTwoFactorAuthenticationEnabled">
          <Button
            @click="visible = false"
            :label="t('cancel')"
            icon="pi pi-times"
            text
          />
          <Button
            @click="submit"
            :label="t('save')"
            icon="pi pi-check"
            autofocus
            :loading="isLoading"
            :disabled="code.length !== 6"
          />
        </div>
        <Button v-else @click="visible = false" :label="t('close')" text />
      </Transition>
    </template>
  </Dialog>

  <Dialog
    :header="t('title')"
    :visible="explanationVisible"
    @update:visible="explanationVisible = $event"
    style="width: 420px"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
  >
    <div class="leading-6 md:py-2">
      <p class="my-0 whitespace-pre-wrap">
        {{ t('explanation') }}
      </p>
    </div>

    <template #footer>
      <Button
        @click="explanationVisible = false"
        :label="t('close')"
        icon="pi pi-times"
        text
      />
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Two-Step Verification
  code_label: Two-step verification code
  code_placeholder: Enter code
  key_to_copy: Key to paste manually
  success_title: Two-step authentication successfully configured
  codes_hint: Codes to recover if you lose access to the two-factor authentication application. Be sure to keep them in a
    safe place.
  close: Close
  cancel: Cancel
  save: Save
  delete: Delete
  detailed: Detailed
  message:
    key_copied: Key copied
    completed: Two-step authentication successfully configured
    check_failed: An error occurred while checking the code
    cancellation_title: Confirm security downgrade
    cancellation_hint: Canceling two-step verification will make your account less secure!
    cancellation_accept: Confirm
    cancellation_decline: Cancel
    cancellation_success: Two-step verification successfully canceled
  explanation_title: How it works
  explanation: >
    Two-factor authentication will make your account more secure. When you enable the function, an additional
    verification step will appear during account login, where you will need to enter a special code from the Two-Factor
    Authentication application.


    To configure the function, you need to download a special application, for example, Google Authenticator. Then scan
    the QR code through the application (or copy the secret key manually). Immediately after this, a unique code will
    appear in the application, which will be updated at intervals.


    Next, to complete the setup, you will need to enter the code from this application, and then additional protection
    will be activated. You can cancel the additional security level at any time.

ru:
  title: Двухэтапная аутентификация
  code_label: Код двухэтапной аутентификации
  code_placeholder: Введите код
  key_to_copy: Ключ для вставки в ручную
  success_title: Двухшаговая аутентификация успешно настроена
  codes_hint: Коды для восстановления в случае потери доступа к приложению двухфакторной аутентификации. Обязательно
    сохраните их в надежное место.
  close: Закрыть
  cancel: Отменить
  save: Сохранить
  delete: Удалить
  detailed: Подробнее
  message:
    key_copied: Ключ скопирован
    completed: Двухшаговая аутентификация успешно настроена
    check_failed: Во время проверки кода произошла ошибка
    cancellation_title: Подтвердите понижение безопасности
    cancellation_hint: Отмена двухшаговой аутентификации сделает ваш аккаунт менее безопасным!
    cancellation_accept: Подтвердить
    cancellation_decline: Отменить
    cancellation_success: Двухшаговая аутентификация успешно отменена
  explanation_title: Как это работает
  explanation: >
    Двухфакторная аутентификация сделает ваш аккаунт более защищенным. При включении функции во время входа в аккаунт
    появится дополнительный этап проверки, где будет необходимо ввести специальный код из приложения Двухфакторной
    аутентификации.


    Для настройки функции необходимо скачать специальное приложение, например, Google Authenticator. Затем через
    приложение отсканировать QR код (или скопировать секретный ключ в ручную). Сразу после этого в приложении появится
    уникальный код, который будет обновляться через промежутки времени.


    Далее для завершения настройки будет необходимо ввести код из этого приложения, и тогда дополнительная защита будет
    активирована. Отменить дополнительный уровень безопасности можно будет в любой момент.

de:
  title: Zweistufige Verifizierung
  code_label: Zweistufiger Bestätigungscode
  code_placeholder: Code eingeben
  key_to_copy: Manueller Einsteckschlüssel
  success_title: Zweistufige Authentifizierung erfolgreich konfiguriert
  codes_hint: Wiederherstellungscodes, wenn Sie den Zugriff auf die Zwei-Faktor-Authentifizierungsanwendung verlieren.
    Bewahren Sie sie unbedingt an einem sicheren Ort auf.
  close: Schließen
  cancel: Stornieren
  save: Speichern
  delete: Löschen
  detailed: Weitere Details
  message:
    key_copied: Schlüssel kopiert
    completed: Zweistufige Authentifizierung erfolgreich konfiguriert
    check_failed: Beim Überprüfen des Codes ist ein Fehler aufgetreten
    cancellation_title: Bestätigen Sie die Sicherheitsherabstufung
    cancellation_hint: Wenn Sie die Bestätigung in zwei Schritten entfernen, wird Ihr Konto weniger sicher!
    cancellation_accept: Bestätigen
    cancellation_decline: Stornieren
    cancellation_success: Die zweistufige Authentifizierung wurde erfolgreich abgebrochen
  explanation_title: Wie funktioniert das
  explanation: Die Zwei-Faktor-Authentifizierung macht Ihr Konto sicherer. Wenn Sie die Funktion aktivieren, erscheint bei
    der Anmeldung bei Ihrem Konto ein zusätzlicher Verifizierungsschritt, in dem Sie einen speziellen Code aus der
    Zwei-Faktor-Authentifizierungsanwendung eingeben müssen. Um die Funktion zu konfigurieren, müssen Sie eine spezielle
    Anwendung herunterladen, beispielsweise Google Authenticator. Scannen Sie dann den QR-Code über die Anwendung (oder
    kopieren Sie den geheimen Schlüssel manuell). Unmittelbar danach erscheint in der Anwendung ein eindeutiger Code,
    der in regelmäßigen Abständen aktualisiert wird. Um die Einrichtung abzuschließen, müssen Sie als Nächstes den Code
    dieser Anwendung eingeben. Anschließend wird der zusätzliche Schutz aktiviert. Sie können die zusätzliche
    Sicherheitsstufe jederzeit kündigen.

es:
  title: Verificación de dos pasos
  code_label: Código de verificación de dos pasos
  code_placeholder: Introduce el código
  key_to_copy: Llave de inserción manual
  success_title: Autenticación en dos pasos configurada correctamente
  codes_hint: Códigos de recuperación si pierde el acceso a la aplicación de autenticación de dos factores. Asegúrese de
    guardarlos en un lugar seguro.
  close: Cerca
  cancel: Cancelar
  save: Ahorrar
  delete: Borrar
  detailed: Más detalles
  message:
    key_copied: Clave copiada
    completed: Autenticación en dos pasos configurada correctamente
    check_failed: Se produjo un error al verificar el código.
    cancellation_title: Confirmar la degradación de seguridad
    cancellation_hint: ¡Eliminar la verificación en dos pasos hará que tu cuenta sea menos segura!
    cancellation_accept: Confirmar
    cancellation_decline: Cancelar
    cancellation_success: La autenticación en dos pasos se canceló con éxito
  explanation_title: Cómo funciona esto
  explanation: La autenticación de dos factores hará que su cuenta sea más segura. Cuando habilite la función, aparecerá
    un paso de verificación adicional al iniciar sesión en su cuenta, donde deberá ingresar un código especial de la
    aplicación de autenticación de dos factores. Para configurar la función, debe descargar una aplicación especial, por
    ejemplo, Google Authenticator. Luego escanee el código QR a través de la aplicación (o copie la clave secreta
    manualmente). Inmediatamente después de esto, aparecerá un código único en la aplicación, que se actualizará
    periódicamente. A continuación, para completar la configuración, deberá ingresar el código de esta aplicación y
    luego se activará la protección adicional. Puedes cancelar el nivel de seguridad adicional en cualquier momento.

fr:
  title: Vérification en deux étapes
  code_label: Code de vérification en deux étapes
  code_placeholder: Entrez le code
  key_to_copy: Clé d'insertion manuelle
  success_title: Authentification en deux étapes configurée avec succès
  codes_hint: Codes de récupération si vous perdez l'accès à l'application d'authentification à deux facteurs.
    Assurez-vous de les conserver dans un endroit sûr.
  close: Fermer
  cancel: Annuler
  save: Sauvegarder
  delete: Supprimer
  detailed: Plus de détails
  message:
    key_copied: Clé copiée
    completed: Authentification en deux étapes configurée avec succès
    check_failed: Une erreur s'est produite lors de la vérification du code
    cancellation_title: Confirmer la mise à niveau de sécurité
    cancellation_hint: La suppression de la vérification en deux étapes rendra votre compte moins sécurisé !
    cancellation_accept: Confirmer
    cancellation_decline: Annuler
    cancellation_success: L'authentification en deux étapes a été annulée avec succès
  explanation_title: Comment cela marche-t-il
  explanation: L'authentification à deux facteurs rendra votre compte plus sécurisé. Lorsque vous activez la
    fonctionnalité, une étape de vérification supplémentaire apparaîtra lors de la connexion à votre compte, où vous
    devrez saisir un code spécial à partir de l'application d'authentification à deux facteurs. Pour configurer la
    fonction, vous devez télécharger une application spéciale, par exemple Google Authenticator. Scannez ensuite le code
    QR via l'application (ou copiez la clé secrète manuellement). Immédiatement après, un code unique apparaîtra dans
    l'application, qui sera mis à jour à intervalles réguliers. Ensuite, pour terminer la configuration, vous devrez
    saisir le code de cette application, puis une protection supplémentaire sera activée. Vous pouvez annuler le niveau
    de sécurité supplémentaire à tout moment.
</i18n>
